import React from "react";
import Banner from "./Banner";
import DB from "./DB";
import God from "./God";

class BetsBox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Amount:40,
    };
    this.SetAmount = this.SetAmount.bind(this);
    this.handleTyping = this.handleTyping.bind(this);
    this.MakeBet = this.MakeBet.bind(this);
    this.GetPercent = this.GetPercent.bind(this);
  }

  SetAmount(amt){
    // console.log("SET AMOUNT: " + amt + " / " + this.props.Score);
    amt = Math.min(amt,this.props.Score);
    this.setState({Amount:amt});
  }

  MakeBet(amt){
    let who =this.props.Target;
    // console.log("MAKE BET: " + who + " / "+ this.state.Amount);
    if (amt == undefined)
      amt = this.state.Amount;
    if (!amt || amt < 0) amt = 0;
    amt = Math.min(amt,this.props.Score);
    if (amt != this.state.Amount)
      this.setState({Amount: amt});
    this.props.MakeBet(who,amt);
  }

  handleKeyPress(e){
    // console.log("HKP: " + e.target.value);
    if (e.key === 'Enter')
    {
      this.MakeBet();
    }
  }

  handleTyping(e){
    let amt = parseInt(e.target.value);
    if (!amt || amt < 0) amt = 0;
    amt = Math.min(amt,this.props.Score);
    this.setState({Amount: amt});
  }

  GetPercent(amt){
    return Math.round(amt * this.props.Score);
  }

  render() {
    // console.log("BB A: " + JSON.stringify(this.props.Target));
    // console.log("BB B: " + JSON.stringify(this.props.Status));
    // console.log("BB C: " + JSON.stringify(this.props.YourBet));
    
    let status = this.props.Status;
    let locked = status.Locked;
    // console.log("JLI: " + this.props.JLI + " / " + status.Locked + " / " + JSON.stringify(status))
    if (this.props.JLI)
      locked = true;
    let text = locked ? "BETTING IS CLOSED" : "PLACE YOUR BET";
    let lr = this.props.left ? "Left" : "Right";
    let myBet = false;
    if (this.props.YourBet.Who)
    {
      locked = true;
      text = "BET PLACED"
      if ((this.props.left && this.props.YourBet.ID == 'Red') || (!this.props.left && this.props.YourBet.ID == 'Blue'))
      myBet = true;
    }

    let bann = this.props.left ? 
        [4.1875,66.111,13.625,2.778] :
        [82.25,66.111,13.625,2.778];
    let hands = [{left:(bann[0] + bann[2]*0.192)+'vw',top:(bann[1]+bann[3]-.3)+'vh'},
      {left:(bann[0] + bann[2]*0.736)+'vw',top:(bann[1]+bann[3]-.3)+'vh',transform:'scaleX(-1)'}]

    let betbox = null;
    if (this.props.BuyMode && this.props.Account && this.props.Mon){
      let acct = this.props.Account.stats;
      text = "CHOOSE AN ACTION";
      let options = [];
      // console.log("ACCT: " + JSON.stringify(acct));
      options.push(<div key={"o"+options.length} className="BuyOption Static">Unspent Platinum: {this.props.Plat}/{acct.totalplat}</div>);
      if (!this.props.Mon.Owner){
        if (this.props.Plat > 0){
          options.push(<div key={"o"+options.length} className="BuyOption" onClick={e=>{God.BS.Set("ConfirmBox",{Type:"Adopt",Monster:this.props.Mon})}}>Adopt Monster<br/>Cost: 1 Platinum</div>)
        }else{
          options.push(<div key={"o"+options.length} className="BuyOption Invalid">Adopt Monster<br/>Cost: 1 Platinum</div>)
        }
      }else if (this.props.Mon.Owner.ID == this.props.Account.id && this.props.Mon.Owner.PetID){//{Type:"NamePet",Message:res.Message,MonID:res.MonID,Name:this.props.info.Monster.Name}
        options.push(<div key={"o"+options.length} className="BuyOption" onClick={e=>{God.BS.Set("ConfirmBox",{Type:"NamePet",Message:"Rename Monster -- Cost: Free",MonID:this.props.Mon.Owner.PetID,Name:this.props.Mon.Name})}}>Rename Monster<br/>Cost: Free</div>)
      }
      if (this.props.CanCheer){
        options.push(<div key={"o"+options.length} className="BuyOption" onClick={e=>{God.BS.SendChat(God.ChatInput,"Rose.png",this.props.left)}}>Cheer<br/>throw a rose</div>)
      }
      betbox = <div className={"BuyOptionHolder " + lr}>{options}</div>;
    }
    else if (!locked || myBet){
      let bets = [.2,.4,.8];
      let betn = ["One","Two","Three"];
      let quickbet = [];
      for(let n = 0; n < 3;n++){
        if(this.props.Score - (this.props.Score * bets[n]) <= 50)
          quickbet.push(<div key={"QB"+betn[n]} className={"QuickBet "+betn[n]} onClick={e=>this.setState({Amount:this.GetPercent(1)})}>All In</div>);
        else
          quickbet.push(<div key={"QB"+betn[n]} className={"QuickBet "+betn[n]} onClick={e=>this.setState({Amount:this.GetPercent(bets[n])})}>Bet {bets[n]*100}%</div>);
      }
      // (<div><div className="QuickBet One" onClick={e=>this.setState({Amount:this.GetPercent(.2)})}>Bet 20%</div>
      //   <div className="QuickBet Two" onClick={e=>this.setState({Amount:this.GetPercent(.4)})}>Bet 40%</div>
      //   <div className="QuickBet Three" onClick={e=>this.setState({Amount:this.GetPercent(.8)})}>Bet 80%</div></div>);
      // if(this.props.Score <= 50) quickbet = 
      //   (<div><div className="QuickBet One" onClick={e=>this.setState({Amount:this.GetPercent(1)})}>All In</div>
      //   <div className="QuickBet Two" onClick={e=>this.setState({Amount:this.GetPercent(1)})}>All In</div>
      //   <div className="QuickBet Three" onClick={e=>this.setState({Amount:this.GetPercent(1)})}>All In</div></div>);
      let core = (myBet ? 
        (<div className={"BetInputBox Active " + lr}>
          <div className='YourBet'><img className="Coin" src="/Art/UI/BetCoin.png"/>{this.props.YourBet.Amount}</div>
        </div>) : 
        (<div className={"BetInputBox " + lr}>
          <input className='BetInput' value={this.state.Amount} onChange={e=>this.handleTyping(e)} onKeyPress={e=>this.handleKeyPress(e)}/>
          <div className="BIEA">Enter Amount</div>
          {quickbet}
          <div className="QuickBet Four" onClick={e=>this.MakeBet(this.state.Amount)}>Place Bet</div>
        </div>));
      betbox = (<div>
        <img src="Art/UI/BetHands.png" className="BetHands" style={hands[0]}/>
        <img src="Art/UI/BetHands.png" className="BetHands" style={hands[1]}/>
        {core}
        </div>);
    }

    
    return <div><Banner Text={text} Left={bann[0]} Top={bann[1]} Width={bann[2]} Height={bann[3]}/>  
      
      {betbox}
      {/* <span className={status.Class}>{text}</span><br/>
      {yourBet}
      Gold: {this.props.Score}<br/>
      Odds: {this.props.Totals.Red} : {this.props.Totals.Blue}
      {inputs} */}
    </div>;
  }
}

export default BetsBox;