import React, { useState, useCallback, useEffect, useRef } from "react";

const SCROLL_BOX_MIN_HEIGHT = 20;

export default function Scrollbar({ Offset, Invert,children, className, ...restProps }) {
  // const [hovering, setHovering] = useState(false);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
  const [isDragging, setDragging] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [locked, setLocked] = useState(true);
  const scrollH = 100;

  const handleDocumentMouseUp = useCallback(
    e => {
      if (isDragging) {
        e.preventDefault();
        setDragging(false);
      }
    },
    [isDragging]
  );

  const handleDocumentMouseMove = useCallback(
    e => {
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();
        const scrollHostElement = scrollHostRef.current;
        const { scrollHeight, offsetHeight } = scrollHostElement;

        let deltaY = e.clientY - lastScrollThumbPosition;
        let percentage = deltaY * (scrollHeight / offsetHeight);

        setScrollThumbPosition(e.clientY);
        setScrollBoxTop(
          Math.min(
            Math.max(0, scrollBoxTop + deltaY),
            offsetHeight - scrollBoxHeight
          )
        );
        scrollHostElement.scrollTop = Math.min(
          scrollHostElement.scrollTop + percentage,
          scrollHeight - offsetHeight
        );
      }
    },
    [isDragging, lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop]
  );

  const handleScrollThumbMouseDown = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setScrollThumbPosition(e.clientY);
    setDragging(true);
    // console.log("handleScrollThumbMouseDown");
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef) {
      return;
    }
    const scrollHostElement = scrollHostRef.current;
    const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

    let newTop =
      (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
    // newTop = newTop + parseInt(scrollTop, 10);
    newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
    setScrollBoxTop(newTop);
    let l = scrollTop + offsetHeight > scrollHeight - 1;
    // console.log("L: " + l + " / " + newTop + " / " + scrollHeight + " / " + scrollBoxHeight + " / " + scrollTop + ' / ' + offsetHeight)
    setLocked(l);
  }, []);

  // 0                  / 399 / 20  / 0                 / 234 -- default, scroll to oldest
  // 96.76691729323308  / 399 / 20  / 165.3333282470703 / 234 -- default, scroll to newest
  // 0                  / 638 / 20  / 0                 / 234 -- more lines, scroll to oldest
  // 148.17554858934167 / 638 / 20  / 404               / 234 -- more lines, scroll to newest

  const scrollHostRef = useRef();

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    const { clientHeight, scrollHeight } = scrollHostElement;
    const scrollThumbPercentage = clientHeight / scrollHeight;
    const scrollThumbHeight = Math.max(
      scrollThumbPercentage * clientHeight,
      SCROLL_BOX_MIN_HEIGHT
    );
    setScrollBoxHeight(scrollThumbHeight);
    setMounted(true);
    scrollHostElement.addEventListener("scroll", handleScroll, true);
    return function cleanup() {
      scrollHostElement.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    //this is handle the dragging on scroll-thumb
    document.addEventListener("mousemove", handleDocumentMouseMove);
    document.addEventListener("mouseup", handleDocumentMouseUp);
    document.addEventListener("mouseleave", handleDocumentMouseUp);
    return function cleanup() {
      document.removeEventListener("mousemove", handleDocumentMouseMove);
      document.removeEventListener("mouseup", handleDocumentMouseUp);
      document.removeEventListener("mouseleave", handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  useEffect(() => {
    if (mounted){
      const scrollHostElement = scrollHostRef.current;
      if (Invert && locked){
        scrollHostElement.scrollTo(0,scrollHostElement.scrollHeight - scrollBoxHeight);
      }
      const { clientHeight, scrollHeight } = scrollHostElement;
      const scrollThumbPercentage = clientHeight / scrollHeight;
      const scrollThumbHeight = Math.max(
        scrollThumbPercentage * clientHeight,
        SCROLL_BOX_MIN_HEIGHT
      );
      if (scrollBoxHeight != scrollThumbHeight)
        setScrollBoxHeight(scrollThumbHeight);
      }
  });

  
    // console.log("X: " + Invert + " / " + locked);
    

    // const scrollHostElement = scrollHostRef.current;
    // const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

    // let newTop =
    //   (parseInt(scrollTop, 10) / parseInt(scrollH, 10)) * offsetHeight;
    // // newTop = newTop + parseInt(scrollTop, 10);
    // newTop = Math.min(newTop, offsetHeight - scrollH);
    // if (scrollBoxTop != newTop)
    //   setScrollBoxTop(newTop);
  // }

  return (
    <div
      className={"scrollhost-container"}
      // onMouseOver={handleMouseOver}
      // onMouseOut={handleMouseOut}
    >
      <div
        ref={scrollHostRef}
        id={'beep'}
        className={`scrollhost ${className}`}
        {...restProps}
      >
        {children}
      </div>
      <div className={"scroll-bar"} style={{right:"-"+(Offset != undefined ? Offset : 0)+"vw"}}>
        <div
          className={"scroll-thumb"}
          style={{ height: scrollBoxHeight, top: scrollBoxTop }}
          onMouseDown={handleScrollThumbMouseDown}
        />
      </div>
    </div>
  );
}