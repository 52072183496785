import React from "react";

class DieImg extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Shown: 0,
    };
    this.mounted = false;
  }

  componentDidMount(){
    this.mounted = true;
    this.setState({Shown: (this.props.preview ? "?" : this.props.die)});
    setTimeout(() => { 
      if (!this.mounted) return;
      this.setState({Shown:(this.props.preview ? "?" : this.props.die)})
    } , 500);
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  render() {
    let roll = this.props.roll;
    let size = roll ? roll.Parsed.Size : this.props.size;
    let clss = "DieContainer D" + size;
    if (this.props.class)
      clss += " " + this.props.class;
    if (!this.props.preview){
      let chosen = !roll || roll.Parsed.Adv == 0 || this.props.die == roll.RawRoll;
      if (!chosen) clss += " Unchosen";
      else if (roll && roll.Succeed !== undefined) clss += " " + (roll.Succeed ? "Success" : "Failure");
    }
    return <div className={clss} >
        <span className="DieTextFG" text={this.state.Shown}>{this.state.Shown}</span>
      </div>;
  }
}//<span className={"LogDie"}>

export default DieImg;