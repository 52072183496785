import React from "react";
import God from "./God.js";

class ProcBody extends React.Component {

  constructor(props){
    super(props);
    this.state = {
    }
  }

  GetPart(bpart,n,size,anchor){
    let info = this.props.who.ProcBody[bpart];
    let style = {};
    let part = bpart;
    if(part == "OffArm")
      part = "Arms";
    let img = "Art/ProcBods/"+part+info.Sheet+".png";
    let left = anchor.left;
    let bot = anchor.bottom;
    let slot = info.Slot;
    let brightmod = 1;
    let frames = 6 * 100;
    let ratio = 4;
    switch(part){
      case "Pet":{
        img = "Art/"+info.Sheet+".png";
        slot = 0;
        frames = 100;
        ratio = 1;
        size = 30;
        left -= 1 / 8.5 * size;
        bot += 0.5 / 8.5 * size //0.07;
        // style.backgroundColor = "red";
        break;
      }
      case "Held":{
        style.transform = "scaleX(-1)";
        left -= 3.65 / 8.5 * size //;3.65;
        bot -= 0.07 / 8.5 * size //0.07;
        if(this.props.who.ProcBody.Arms && this.props.who.ProcBody.Arms.Slot == 2)
          bot += 2.2 / 8.5 * size //2.2;
        if(this.props.who.ProcBody.Torso && (this.props.who.ProcBody.Torso.Slot == 0 || this.props.who.ProcBody.Torso.Slot == 2))
          left += 0.54 / 8.5 * size //0.54;
        break;
      }
      case "Arms":{
        left -= 1.5 / 8.5 * size //1.5;
        bot += 0.5 / 8.5 * size //0.5;
        if (bpart == "OffArm") {
          left += 2.5 / 8.5 * size //2.5;
          if (this.props.who.ProcBody.OffHand && !this.props.who.ProcBody.OffHand.HeldUp)
          {
            slot = 0;
          }
        }
        else{
          brightmod = 0.75;
          style.transform = "scaleX(-1)";
          style.zIndex = -6;
        }
        if (this.props.who.ProcBody.Torso){
          let alt = bpart == "OffArm";
          // this.props.who.ProcBody.Torso.Slot = 0;
          // slot = 1;
          switch (this.props.who.ProcBody.Torso.Slot){
            case 0:left *= alt ? 1 : 1.04;  break;
            case 2:{
              if (slot == 2 && !alt) left -= 0.5 / 8.5 * size //0.5;
              slot += 3;
              left *= alt ? 1 : 1.08;
              bot += 0.54 / 8.5 * size //0.54;
              break;
            }
            case 3:{
              if (slot == 2 && !alt) left -= 0.52 / 8.5 * size //0.52;
              slot += 3;
              left *= alt ? 1 : 1.04;
              bot += 0.54 / 8.5 * size //0.54;
              break;
            }
            case 4:case 5: {
              if (slot == 1) bot += alt ? 0.53 / 8.5 * size  : 0;
              break;
            }
          }
          // bot -= alt ? 0 : 0;
          
        // } && (this.props.who.ProcBody.Torso.Slot == 2 || this.props.who.ProcBody.Torso.Slot == 3)){
          // slot += 3;
          // left *= 1.035;
        }
          
        break;//
      }
      case "OffHand":{
        left += 2.6 / 8.5 * size //2.6;//1.6;
        bot -= 0.55 / 8.5 * size //0.55;
        style.transform = "scaleX(-1)";
        break;
      }
      // case "ETorso":case "Beard":return;
      // case "Torso":{slot = 0;break;}
    }
    // console.log("PART: " + part + " / " + img)
    style.backgroundImage = "url("+img+")";
    style.left = left+"vmin";
    style.bottom = bot+"vmin";
    let currFrame = slot * -(size);
    
    style.backgroundPosition = currFrame+"vmin 0";
    style.backgroundSize = frames +'% 100%';
    style.width = size+"vmin";
    style.height = (size*ratio)+"vmin";
    // if (part == "Torso" || part == "Head" || part == "Legs") info.Color = {S:50,B:50,H:30};
    // if (part == "Hair" || part == "Beard") info.Color = {S:50,B:150,H:-30};//{S:[50,100],B:[100,150],H:[-30,30]}
    

    if (info.Color) style.filter = "saturate("+info.Color.S+"%) brightness("+info.Color.B*brightmod+"%) hue-rotate("+info.Color.H+"deg)";
    return <div key={"pb"+n} className={"ProcPart Proc"+part} style={style}></div>;
  }
  
  render() {
    let size = 8.5;
    let bodyParts = [];
    let anchor = {left:14,bottom:0.1};
    if (this.props.portrait){
      if (this.props.big){
        size = 7.5;
        anchor = {left:-0.8,bottom:-7.21};
      }
      else{
        size = 6;
        anchor = {left:-0.5,bottom:-6};
      }
      if (this.props.who.ProcBody.Legs && this.props.who.ProcBody.Legs.Slot >= 4){
        anchor.bottom += this.props.big ? 0.5 : 1;
      }
    }
    else if (this.props.big){
      size = 18;
      anchor = {left:27,bottom:-0.5};
    }
    for(let part in this.props.who.ProcBody){
      if (part == "EHead") continue;
      bodyParts.push(this.GetPart(part,bodyParts.length,size,anchor));
      switch(part){
        // case "Arms":{
        //   bodyParts.push(this.GetPart(part,bodyParts.length,size,anchor,true));
        //   break;
        // }
        case "ELegs":{
          if (this.props.who.ProcBody.Legs.Slot >= 4) anchor.bottom -= size / 7.8;
          break;
        }
      }
    }
    
    return <div className={"ProcBody "+this.props.clInfo}>{bodyParts}</div>;
  }
}

export default ProcBody;