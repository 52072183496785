const Slurs = {
  Setup:false,
  Audit(msg){
    this.Init();
    if (!msg)
      return 0;
    for(let s in this.Words){
      if (msg.includes(s))
        return 1;
    }
    msg = this.Compress(msg);
    for(let s in this.Words){
      if (msg.includes(s))
        return this.Words[s];
    }
    return 0;
  },
  Compress(msg){
    msg = msg.toLowerCase().replaceAll("1","i").replaceAll("4","a").replaceAll("3","e").replaceAll("2","z")
      .replaceAll("5","s").replaceAll("8","b").replaceAll("9","g").replaceAll("0","o").replaceAll("!","i").replaceAll("7","t");
      // .replaceAll(" ","").replaceAll(".","").replaceAll(",","").replaceAll("z","s");
    let tmp = "";
    let last = "";
    for(let n = 0;n < msg.length;n++){
      let l = msg.substring(n,n+1);
      if (last != l)
        tmp += l;
      last = l;
    }
    // console.log(msg + " => " + tmp);
    return tmp;
  },
  Init(){
    if(this.Setup) return;
    this.Setup = true;
    for(let s of this.T1)
      this.Words[this.Compress(s)] = 1;
    for(let s of this.T2)
      this.Words[this.Compress(s)] = 1; //Not drawing a distinction for the moment
    //console.log("INIT: " + JSON.stringify(this.Words))
  },
  AuditName(nam){
    if (Slurs.MaxName && nam.length > Slurs.MaxName)
      return false;
    if (Slurs.Audit(nam) != 0)
      return false;
    return true;
  },//FuckIkeplosingmoney
  //sissy???,"spic","rape","tard"
  T0:["gook","reee"],
  T1:["cuck","fag","gypsy","bitch","beaner","cocksucker","dyke","nigga","oriental","whore","slut",
    "goyim","a jew","the jews","deus vult","rape","white man","white men","white women","maga","pence",
    "shock therapy","trump","Make America Great Again","wight pow","wight suprem","sjw", "the blacks", "social justice warrior",
    "cock", "pussy","autist","suck my","suck a","raping"],//"biolo","sports","obama","tranz"
  T2:["nigger","faggot","tranny","trannies","1488","heil hitler","towelhead","wetback","cunt","camel fucker","hooknose","jigaboo",
        "porch monkey","must secure the existence of","redskin","big black cock","kike"],
  Words:{}
}

export default Slurs;