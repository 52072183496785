import React from "react"; 
import God from "./God.js";
import ProcBody from "./ProcBody.js";

class Portrait extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      Img:null,
    };
    this.updateCanvas = this.updateCanvas.bind(this);
    this.canv = React.createRef();
  }

  componentDidMount() {
      let who = this.props.Who;
      let src = God.GetArtURL(who,this.props.Src);//'/Art/'+(who ? who.Class.replaceAll(" ","") : "UI/"+this.props.Src)+".png";
      let img = new Image();
      let self = this;
      img.onload = function() {
        self.updateCanvas(img);
      };
      img.src = src;
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.Who != prevProps.Who){
      let who = this.props.Who;
      let src = God.GetArtURL(who,this.props.Src);//'/Art/'+(who ? who.Class.replaceAll(" ","") : "UI/"+this.props.Src)+".png";
      let img = new Image();
      let self = this;
      img.onload = function() {
        self.updateCanvas(img);
      };
      img.src = src;
    }
  }
  updateCanvas(img) {
      let who = this.props.Who;
      if (!this.canv || !this.canv.current) return;
      const ctx = this.canv.current.getContext('2d');
      ctx.imageSmoothingEnabled = false;
      let ftemp = who.Face ? who.Face : {X:44,Y:70};
      let face = {X:ftemp.X+(ftemp.X/10),Y:ftemp.Y-2};
      let zoom = 5;
      ctx.clearRect(0,0,100,100);
      ctx.drawImage(img,(50-zoom*face.X),(50-zoom*face.Y),100*zoom,100*zoom);
  }
  
  render() {
    let who = this.props.Who;
    // let src = '/Art/'+(who ? who.Class.replaceAll(" ","") : "UI/"+this.props.Src)+".png";
    let clss = this.props.Class;
    let lr = this.props.Left ? "Left" : "Right";
    let big = "";
    let bigOffset = 0;
    if (this.props.Profile){
      clss += " PetProfile " + lr;
      big = " Big";
      bigOffset = -2.14;
    }
    let style = {};
    if (who && who.Face){
      if (who.Tags.Shiny) {
        style.filter = "hue-rotate("+who.Tags.Shiny+"deg)";
      }
      lr += " PCanvas"//MonFace"
      // let ftemp = who.Face ? who.Face : {X:44,Y:70};
      // let face = {X:ftemp.X,Y:ftemp.Y};
      // if (this.props.Left) face.X = 100-face.X;
      // style.left = (face.X /-10+1+(bigOffset * (this.props.Left ? 1 : 0.45)))+'vw';//-3, 47
      // style.top = ((face.Y) /-10+1.5+(bigOffset*1.1))+'vw';//-5, 70
      // let clips = this.props.Left ? [face.Y-13.25,face.X-18,91.75-face.Y,92.5-face.X] : [face.Y-13.25,85-face.X,91.75-face.Y,face.X-9.5];
      // let cltxt = 'inset('+clips[0]+'% '+clips[1]+'% '+clips[2]+'% '+clips[3]+'%)'
      
      // style['clipPath'] = cltxt;
    }
    let bod = <canvas className={"Portrait "+lr+big} ref={this.canv} width="100" height="100" style={style}/>;
    if(who.ProcBody)
      bod = <ProcBody clInfo={"Portrait " +lr+big} who={who} left={this.props.Left} portrait={true} big={this.props.Profile}/>;

    return <div key={"Portrait"+who.Name }className={clss} >
        <img className={"Portrait BG"+big} src="/Art/UI/StatIconBG.png"/>
        {/* <img className={"Portrait " + lr+big} src={src} style={style}/> */}
        {/* <canvas className={"Portrait "+lr+big} ref={this.canv} width="100" height="100" style={style}/> */}
        {bod}
        <img className={"Portrait FG"+big} src="/Art/UI/StatIconFG.png"/>
      </div>;
  }
}//<span className={"LogDie"}>

export default Portrait;