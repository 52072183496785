import React from "react";

function Username(props) {
  let cl = "ProfLink";
  if (props.class) cl += " " + props.class;
  // console.log("CL: " + cl)
  return <a className={cl} href={"#/pc/"+props.id} target="_blank">{props.name}</a>;
  // return <span>{props.name}</span>;
}

export default Username;