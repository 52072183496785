import React from "react";
import God from "./God.js";
import ProcBody from "./ProcBody.js";

class MIcon extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      Frame: 0,
      SSAnim: null,
      // Lock:false,
      ActiveAnim:null,
      Running:true,
      Effect:null,
      EFrame:0,
    }
    
    this.NextFrame = this.NextFrame.bind(this);
    this.NextEFrame = this.NextEFrame.bind(this);
    // this.TestLoop = this.TestLoop.bind(this);
    this.NextFrame();
    // this.TestLoop();
  }

  // TestLoop(){
  //   this.NextEFrame("Acid");
  //   setTimeout(() => {
  //     this.TestLoop();
  //     console.log("TEST LOOP!");
      
  //   }, 2000);
  // }

  // componentDidMount(){
  //   if (this.props.who){
  //     this.NextFrame();
  //     this.setState({Frame:0,SSAnim:"Idle",ActiveAnim:"Idle"});
  //   }
  // }

  componentDidUpdate(prevProps, prevState, snapshot){
    
    // console.log("CDU1: " + prevProps.who + " / " + this.props.who);
    if (!this.props.who) return;

    if (this.props.Effect && prevProps.Effect != this.props.Effect)
      this.NextEFrame(this.props.Effect);
    // if (prevProps.who != this.props.who){
    //   console.log("NEWMON")
    //   this.setState({Frame:0,SSAnim:null,ActiveAnim:null});
    //   this.NextFrame();
    //   return;
    // }
    let ssanim = this.props.who.Vis.SSAnim;
    let running = false;
    // console.log("CDU2: " + prevState.SSAnim + " / " + this.props.who.Vis.SSAnim);
    if (prevState.SSAnim != ssanim && this.state.SSAnim != ssanim){ 
      // console.log("CDU3: " + ssanim + " / " + this.props.who.Vis.Anim);
      // if (this.state.Lock)
      switch(this.props.who.Vis.Anim){
        case "Attack":{
          setTimeout(() => {
            this.setState({Frame:1});
            setTimeout(() => {this.setState({Frame:0});},400);
          }, 400);
          break;
        }
        case "GetCrit":
        case "GetHit":{
          ssanim = "Block";
          setTimeout(() => {
            this.setState({ActiveAnim:"GetHit",Frame:0});
          }, 400);
          break;
        }
        case "Resist":
        case "Dodge":{
          ssanim = "Block";
          setTimeout(() => {
            this.setState({Frame:1});
          }, 400);
          break;
        }
        // GetCrit, Resist
        default:
          running = true;
          // this.setState({Frame:0});
          // this.NextFrame();
          break;
      }
      this.setState({Frame:0,SSAnim:this.props.who.Vis.SSAnim,ActiveAnim:ssanim,Running:running});
      
    }
  }

  NextFrame(current){
    // if (!this.props.who) return;
    let timer = 200;
    let ssanim = "Idle";
    if (this.props.who && this.props.who.Anims){
      // if (this.props.who.Conditions.Dead) {
      //   ssanim = "Dead";
      //   // this.setState({ActiveAnim:"Dead",Frame:0});
      //   // return;
      // }
      // else if (this.props.who.HP <= 0){
      //   ssanim = "Down";
      //   // this.setState({ActiveAnim:"Down",Frame:0});
      //   // return;
      // }
      ssanim = this.props.who.Vis.SSAnim ? this.props.who.Vis.SSAnim : ssanim;
      // if (current && current != ssanim) return;
      if (this.state.Running && this.props.who.HP > 0 && this.props.who.Anims[ssanim]){
        timer = this.props.who.Anims[ssanim].Speed ? this.props.who.Anims[ssanim].Speed : timer;
        let next = this.state.Frame + 1;
        
      
        if (this.props.who.Anims[ssanim].Length <= next || !current)
          next = 0;
        // console.log("NFA: " + next + " / " + this.props.who.Anims[ssanim].Length + " / " + (next + this.props.who.Anims[ssanim].Start))
        // console.log("NFB: " + next + " / " + this.props.who.Anims.Frame + " / " + this.state.Frame + " / " + this.state.SSAnim)
        this.setState({Frame:next});
      }
    }
    // if (current && current != ssanim || (this.state.Running == (current != undefined))) return;
    // console.log("FRAME PROG: " + ssanim + " / " + timer + " / " + this.props.who + " / " + this.state.Running)
    setTimeout(() => {
      this.NextFrame(ssanim);
    }, timer);
  }

  NextEFrame(effect){

    let length = God.EffectLength(effect);
    // console.log("NEF1: " + effect+ " / " + length + " / " + this.state.Effect)
    if (length <= 0) return;
    let timer = 1000/length;
    let next = this.state.Effect ? this.state.EFrame + 1 : 0;
    if (length <= next || !effect){
      // console.log("NEF X: " + effect + " / " + length+ " / " + next)
      this.setState({Effect:null});
      God.BS.Set(this.props.left ? "LEffect" : "REffect",null);
      return;
    }
    // console.log("NEF2: " + effect + " / " + length+ " / " + next)
    this.setState({EFrame:next, Effect:effect});
    setTimeout(() => {
      this.NextEFrame(effect);
    }, timer);
  }
  
  render() {
    let lr = this.props.left ? "Left" : "Right";
    let who = this.props.who;
    if (!who)
      return null;
    //Okay, this is silly for now, but maybe we'll hit 3 levels some day??
    let zoom = this.props.who.Size > 3 ? false : this.props.zoom > 1; 
    // console.log("ZOOM: " + this.props.who.Size + " / " + this.props.zoom + " / "+ zoom);
    if (zoom) lr += " ZoomedOut";
    let shake = this.props.who.Vis.Shake ? " Shaker" : "";
    let anim = this.props.who.Vis.Anim ? " Anim" + this.props.who.Vis.Anim : "";
    let imgtype = 'MImg';
    let style = {};
    
    let strname = (this.props.who.Art ? this.props.who.Art : this.props.who.Class.replaceAll(" ",""));
    let img = "Art/" + strname + ".png";
    style.backgroundImage = "url("+img+")";
    // style.backgroundRepeat = 'round';
    style.backgroundSize = '100% 100%';
    if (who.Tags.Shiny) {
      style.filter = "hue-rotate("+who.Tags.Shiny+"deg)";
    }
    
    // switch(this.props.who.Vis.Anim){
    //   case "Attack":shadow = null;break;
    //   case "Dodge":shadow = null;break;
    // }
    // if (anim != "")
    //   console.log("ANIM: " + anim);
    let perc = who.TrueMHP ? Math.max(0, who.HP / who.TrueMHP) : 1;
    let percM = who.TrueMHP ? Math.max(0, who.MaxHP / who.TrueMHP) : 1;
    if (perc <= 0)
      {
        lr += " Dead";
        // shadow = null;
      }
    let htcl = this.props.who.Vis.HeadTextCl; 
    if (!htcl) htcl = "";
    
    let bimg = img;
    let altArt = who.Tags.AltArt;
    let monBody = null;
    if(who.ProcBody){
      monBody = <ProcBody clInfo={imgtype + ' ' + lr+anim} who={who} left={this.props.left}/>;
    }
    else if (altArt != undefined){
      img = "Art/Alt/"+strname+altArt.Path+".png";
      style.backgroundImage = "url("+img+")";
      // console.log("ALT ART: " + img);
    }
    else if (who.Anims && !this.props.profile){
      
      let ssanim = this.state.ActiveAnim ? this.state.ActiveAnim : "Idle";
      if (who.Conditions.Dead) ssanim = "Dead";
      else if (who.HP <= 0) ssanim = "Down";
      if (who.Anims[ssanim]){
        // if (ssanim != "Idle"){
        //   console.log("ANIM: " + who.Name + " / " + JSON.stringify(who.Anims))
        // }
        img = img.replace("Art/","Art/Anim/");
        let currFrame = (this.state.Frame + this.props.who.Anims[ssanim].Start) * -34.25;
        style.backgroundImage = "url("+img+")";//+
        style.backgroundPosition = currFrame+"vmin 0";
        let frames = who.Anims.Frame * 100;
        // console.log(frames)
        style.backgroundSize = frames +'% 100%';
        if (who.Anims[ssanim].Override){
          anim = "";
        }
      }
    }
    
    let prof = "";
    let shadProf = "";
    if (this.props.profile) {
      imgtype = "MProfileArt";
      prof = " MProfileArtHolder";
      shadProf = " ShadowProfile";
      if (perc <= 0 && !who.Anims){
        console.log("DEAD")
        anim = " AnimDead";
      }
      if(who.ProcBody)
        monBody = <ProcBody clInfo={imgtype + ' ' + lr+anim} who={who} left={this.props.left} big={true} />;
    }

    if (!monBody)
      monBody = <div className={imgtype + ' ' + lr+anim} style={style} onError={e=>{if (!e.target.src.includes(bimg)) e.target.src = bimg;}}/>;;


    let shadow = perc <= 0 ? null : <img className={'FootShadow ' +lr + anim + shadProf} src={'/Art/UI/FootShadow.png'}/>;
    
    let roll = who.Vis.Roll;// ? who.Vis.Roll : undefined;
    // let preview = false;
    if (!roll) {
      roll = who.Vis.PreRoll;
      // preview = true;
    }
    let dice = God.ShowDice(roll,true)
    let htt = this.props.who.Vis.HeadText;
    let headText = null;
      switch(htcl){
        case 'Action':{
          if (htt) htt = "ACTION: " + htt;
          break;}
        case 'Spell':{
          if (htt) htt = "SPELL: " + htt;
          break;}
        case 'Attack':{
          if (htt) htt = "ATTACK: " + htt;
          break;}
        case 'HIT':{
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={'HIT: '}>{'HIT: '}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
            break;
        }
        case 'CRIT':{
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={'CRIT: '}>{'CRIT: '}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
            break;
        }
        case 'MISS':{
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Red LogSub' text={'MISS: '}>{'MISS: '}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
            break;
        }
        case 'MakeSDmg':
        case 'MakeSave':{
          htt = "Save Vs " + htt +": ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'FailSDmg':
        case 'FailSave':{
          htt = "Save Vs " + htt +": ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Red LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        
        case 'MakeConcentrate':{
          htt = "Concentration: ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'FailConcentrate':{
          htt = "Concentration: ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Red LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'RawSkill':{
          htt = htt +" Roll: ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'MakeSkill':{
          htt = htt +" Roll: ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'FailSkill':{
          htt = htt +" Roll: ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Red LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'Immune':{
          htt = "Save Vs " + htt +": Immune";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={htt}>{htt}</span>
            </div>);
          break;
        }
        
        case 'Init':{
          htcl += " Raised";
          htt = "Roll for Init: " + htt;
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={htt}>{htt}</span><br/>
            <div className={'HTSmallDice SubDice Indent'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'Move':{//Maybe make the part after the ':' into a different color?
          break;
        }
        case 'RechargeSucc':{
          htt = "Recharge " + htt +": ";
          headText = (<div className={'HeadText ' + htcl} key={htcl+htt} >
            <span className='Outline Green LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'RechargeFail':{
          htt = "Recharge " + htt +": ";//style={{"marginLeft":"1vw"}}
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Red LogSub' text={htt} >{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        // case 'Damage':break;
        case 'Damage':{
          htcl += " Raised";
          htt = htt;
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Red LogSub' text={htt}>{htt}</span><br/>
            <div className={'HTSmallDice SubDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'Healing':{
          htcl += " Raised";
          htt = htt;
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={htt}>{htt}</span><br/>
            <div className={'HTSmallDice SubDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'SubRoll':{
          htcl += " Raised";
          htt = "Roll for " + htt + ": ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline Green LogSub' text={htt}>{htt}</span><br/>
            <div className={'HTSmallDice SubDice Indent'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        case 'Multiattack':{
          htt = "Multiattack: ";
          headText = (<div key={htcl+htt} className={'HeadText ' + htcl}>
            <span className='Outline LogSub' text={htt}>{htt}</span>
            <div className={'HTSmallDice'}>{dice}&nbsp;</div>
            </div>);
          break;
        }
        // case 'Healing':break;
        case 'Condition':break;
        case 'Dead':break;
        case 'Victory':break;
        case 'Draw':break;
        case 'LoseCondition':break;
        case 'Desc':break;

        default:{
          // if (htt != null)
          //   console.log("NO HT HANDLER: " + htcl + " / " + htt)
          break;
        }//>>
      }
    if (!headText && htt){
      headText = <div key={htcl+htt} className={'HeadText ' + htcl}><span className='Outline' text={htt}>{htt}</span>
        {/* <div className={'HTSmallDice'}>{dice}&nbsp;</div> */}
      </div>;
    }
    
    // console.log("HT: " + htcl + " / " + htt);
    let bar = this.props.profile ? null : (<div className={'HPBar ' +lr}>
      <div className={'HPBarKnob ' +lr}/>
      <div className={'MName ' + lr} ><span className='Outline Bold' text={this.props.who.Name}>{this.props.who.Name}</span></div>
      <img className={'HPHeart '+lr} src="/Art/UI/Heart.png"/>
      <div className='BarBG'><div className={'BarFGHolder ' +lr}>
        <div className={'BarFG Green ' +lr} style={{"width":(perc*100)+"%"}}/>
        <div className={'BarFG DGreen ' +lr} style={{"width":(perc*100)+"%"}}/>
        <div className={'BarFG Black ' +lr} style={{"width":(percM*100)+"%"}}/>
        <div className={'BarFG Grey ' +lr} style={{"width":"100%"}}/>
      </div></div></div>);
    
    // img = "Art/Alt/"+strname+altArt.Path+".png";
    //   style.backgroundImage = "url("+img+")";
    
    let effect = null;
    if (this.state.Effect) {
      // console.log("EFFECT! " + this.state.Effect)
      let size = 10;
      let estyle = {backgroundImage:"url(Art/Effects/"+this.state.Effect+".png)", width:size+"vmin", height:size+"vmin"};
      let curreFrame = (this.state.EFrame) * -size;
      estyle.backgroundPosition = curreFrame+"vmin 0";
      let eframes = God.EffectLength(this.state.Effect) * 100;
      estyle.backgroundSize = eframes +'% 100%';
      //okay, lets try to place this half way between feet and head
      //feet are at {bottom:-30%, left:44$} Foot pixel:34/64=0.531,55/64=0.859
      let yoffset = (86 - who.Face.Y)/300;
      let xoffset = (53 - who.Face.X)/300 * (this.props.left ? 1 : -1);
      let xbase = this.props.left ? 48 : 40;
      estyle.bottom = "calc(-35% + (" + yoffset + " * var(--monster-size)))";
      estyle.left = "calc("+xbase+"% + (" + xoffset + " * var(--monster-size)))";
      // console.log("ESTYLE: " + estyle.bottom + " / " + who.Face.Y)

      effect = (<div className={"MEffect "+lr} style={estyle}/>)
    }
      
    return <div className={"MIcon " +  lr + shake} >  
      <div className={"MIMGTARGET "+lr}/>
      <div className={'HeadTextHolder ' + lr}><div className='HTHH'>
        {headText}
        {/* <div className={'HTDice'}>{dice}&nbsp;</div> */}
      </div></div>
      <div className={'MImgHolder ' + lr + prof}>
        {/* <img className={imgtype + ' ' + lr+anim} src={img} onError={e=>{if (!e.target.src.includes(bimg)) e.target.src = bimg;}}/> */}
        {monBody}
        {shadow}
        {effect}
        {/* <img className={'MImg MImgBG ' + lr} src={img}/> */}
      </div>
      {bar}
      
    </div>;
  }//style={style}
}

export default MIcon;