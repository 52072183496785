import React from "react";
import God from "./God.js";
import DB from "./DB.js";
import Slurs from "./Slurs.js";

class ConfirmBox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Text:""
    };
  }

  handleTyping(e,field){
    let st = {};
    st[field] = e.target.value;
    this.setState(st);
  }

  render() {
    if (!this.props.info) return null;
    let content = null;
    let buttons = null;
    // content = <span>{this.props.Type}</span>;
    let confirm = (e)=>{};
    switch(this.props.info.Type){
      case "Adopt":{ //{Type:"Adopt",Monster:this.props.Mon}
        content = (<div>Would you like to adopt {this.props.info.Monster.Name}?<br/><br/>
          This will cost 1 Platinum. You currently have {this.props.Plat} Platinum unspent.<br/><br/>
          Note: This feature was just added and may change slightly in the future</div>);
        confirm = (e) =>{
          DB.SendPost("AdoptMon",{monsterID:this.props.info.Monster.ID,monsterClass:this.props.info.Monster.Class},res=>{
            if (res.Success){
              this.setState({Text:this.props.info.Monster.Name})
              God.BS.Set("ConfirmBox",{Type:"NamePet",Message:res.Message,MonID:res.MonID,Name:this.props.info.Monster.Name})
              God.BS.Set("Plat",res.Plat)
              
            }else{
              God.BS.Set("ConfirmBox",{Type:"Result",Message:res.Message})
            }
          })
        };
        break;
      }
      case "NamePet":{ //{Type:"NamePet",Message:res.Message,MonID:res.monID,Name:this.props.info.Monster.Name}
        content = (<div>{this.props.info.Message}<br/><br/>Would you like to name your monster?<br/><br/>
          <input className="LoginInput" autoComplete="pet" value={this.state.Text} onChange={e => this.handleTyping(e,"Text")} minLength={3} maxLength={20}></input>
        </div>);
        confirm = (e) =>{
          let nam = this.state.Text;
          let slurLvl = Slurs.Audit(nam);
          console.log("SLUR: " + slurLvl + " / " + nam);
          if(slurLvl){
            this.props.OnConfirm ? this.props.OnConfirm({Type:"Result",Message:"Invalid Name"}) : God.BS.Set("ConfirmBox",{Type:"Result",Message:"Invalid Name",Name:nam})
            return;
          }
          DB.SendPost("NameMon",{monsterID:this.props.info.MonID,name:this.state.Text},res=>{
            // console.log("RESULT: " + res.Message)
            this.props.OnConfirm ? this.props.OnConfirm({Type:"Result",Message:res.Message}) : God.BS.Set("ConfirmBox",{Type:"Result",Message:res.Message,Name:nam})
          })
        };
        break;
      }
      case "Result":{
        content = <div>{this.props.info.Message}</div>;
        buttons = <div className="WelcomeStart Confirmer" onClick={e=>{God.BS.Set("ConfirmBox",null)}}>Continue</div>
        break;
      }
      default:{
        content = <div>Unknown Value: {this.props.info.Type}</div>;
        break;
      }
    }
    if (!buttons){
      buttons = (<div><div className="WelcomeStart SLeft Confirmer" onClick={confirm}>Confirm</div>
        <div className="WelcomeStart SRight Confirmer" onClick={e=>{God.BS.Set("ConfirmBox",null)}}>Cancel</div></div>);
    }
    return <div className="Block ConfirmWindow">
      {content}
      {buttons}
      
    </div>;
  }//keydown={e=>{console.log("E"+e);this.HitEnter(e);}}
}

export default ConfirmBox;