import React from "react";
import Banner from "./Banner.js";
import God from "./God.js";
import Scrollbar from "./Scrollbar.js";

class BLog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Filters:"ALL",
    };
    this.PassFilters = this.PassFilters.bind(this);
    this.Filters = ["WINS","BETS","ROLL","DMG","ALL"];
  }

  PassFilters(log){
    if (this.state.Filters == "ALL")
      return true;
    let type = log.Info && log.Info.HTC ? log.Info.HTC : '';
    let filter = {};
    switch(this.state.Filters){
      case 'DMG': filter.Damage = true; break;
      case 'WINS': filter.Victory = true; break;
      case 'BETS': filter.Bets = true;break;
      case 'ROLL': return log.Info && log.Info.Roll && (log.Info.Roll.DC || log.Info.Roll.Parsed.Size == 20);
    }
    return filter[type];
  }

  render() {
    let size = [22.19,58.05,30.935,38.12,0.1875];
    let styleA = {left:size[0]+'vw',top:'calc('+size[1]+'vh - '+size[4]+'vw)',width:size[2]+'vw',
      height:'calc('+size[3]+'vh + '+(size[4]*2)+'vw)'};
    let cwid = 2.6875/2;
    let cwid2 = 1.75/2;
    let corners = [{left:"calc("+size[0]+"vw - "+(cwid*1.65)+"vw)",top:"calc("+size[1]+"vh - "+(cwid*0.595 + size[4])+"vw)"},
      {left:"calc("+(size[0]+size[2])+"vw - "+(cwid2*1.25)+"vw)",top:"calc("+size[1]+"vh - "+(size[4])+"vw)",transform:"scaleX(-1)"},
      {left:"calc("+(size[0])+"vw - "+(cwid2*0.56)+"vw)",top:"calc("+(size[1]+size[3]+0.23)+"vh - "+(cwid2*1.42 + size[4])+"vw)"},
      {left:"calc("+(size[0]+size[2])+"vw - "+(cwid2*1.47)+"vw)",top:"calc("+(size[1]+size[3]+0.23)+"vh - "+(cwid2*1.42 + size[4])+"vw)",transform:"scaleX(-1)"}]

    let filters = [];
    for (let f of this.Filters){
      let clss = "BLFilter";
      if (this.state.Filters == f)
        clss += " Selected";
      filters.push(<div key={"F"+filters.length} className={clss} onClick={e=>this.setState({Filters:f})}><div className='BLFT'>{f}</div></div>)
    }
    let entries = [];
    // console.log("LOG: " + this.props.log);
    for(let n = this.props.log.length - 1;n >= 0;n--){
      let big = n == this.props.log.length - 1;
      let clss = "LogLine" + (big ? " Big":"") + (n%2 == 0 ? "" : " Odd");
      if (!big && !this.PassFilters(this.props.log[n])) continue;
      entries.push(<LogLine key={"Blog"+n} class={clss} big={big} log={this.props.log[n]}/>);
      if (n > 0)
        entries.push(<div key={'llb'+entries.length} className="LLBreak"/>)
      if (entries.length > 500) break;
    }
    return <div className="Block BLog">  <div className="BLog2"/><div className="BLog3"/>  
      <Banner Left={23.14} Top={58.79} Width={29.06} Text='BATTLE FEED'/>
      <div className="BLBorder" style={styleA}/><div className="BLBorder2"/>
       <img src="Art/UI/LogTop.png" className="LogTop" style={corners[0]}/>
       <img src="Art/UI/LogTopI.png" className="LogTopI" style={corners[1]}/>
       <img src="Art/UI/LogBot.png" className="LogBot" style={corners[2]}/>
       <img src="Art/UI/LogBot.png" className="LogBot" style={corners[3]}/>
       <div className="BorderGap BLL"/><div className="BorderGap BLR"/>
      <Scrollbar Offset={2} className="BLogScroll">{entries}</Scrollbar>
      <div className="BLogFilters">
        <div className="BLFText">Show only:</div>{filters}
      </div>
    </div>;
  }
}

class LogLine extends React.Component {
  
  render() {
    let log = this.props.log;
    let txt = log.Text;
    let roll = log.Info ? log.Info.Roll : undefined;
    let dice = God.ShowDice(roll,false);
    let clss = this.props.class;
    let plcl = "LLPlus";
    if (log.Info && log.Info.HTC && log.Info.HTC == 'NewBattle')
      clss += " NewBattle";
    else if (this.props.big)
      plcl += " Big"; 
    // console.log("PLCL:"+plcl)
    return <div className={clss}>
      <div className="LogText"><span className={plcl}>{'+ '}</span>{txt}</div>
      <div className="LogRoll"><div className="LogRollC">
        {dice}&nbsp;</div>
      </div>
      <br/></div>;
  }//{roll ? roll.Report : ""}
}




export default BLog;