import React from 'react';
import BWindow from './BWindow.js';
import BetsStatus from './BetsStatus.js';
import Statsheet from "./Statsheet.js";
import BLog from "./BLog.js";
import DB from "./DB.js";
import WS from "./WSc.js";
import ChatWindow from "./ChatWindow.js";
import ChatInput from "./ChatInput.js";
import BetsBox from "./BetsBox.js";
import Events from "./Events.js";
import God from "./God.js";
import Slurs from "./Slurs.js";
import WelcomeWindow from "./WelcomeWindow.js";
// import LoginWindow from "./LoginWindow.js";
import ConfirmBox from "./ConfirmBox.js";
// import ExtraInfo from "./ExtraInfo.js";

class BattleScreen extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Red: null,
      Blue: null,
      Arena:null,
      Model:null,
      Log:[],
      Chat:[],
      Name:"",
      Abilities:[{Key:"Test A",Value:"Test B"},{Key:"Test C",Value:"Test D"}],
      Score:0,
      Plat:0,
      BetStatus:{},
      BetReturns:{},
      BetTotals:{},
      YourBet:{},
      Dist:"",
      Confirmed:true,
      LastBet:false,
      Dead:false,
      // Connections:0,
      Returner:false,
      Muted:false,
      LastChat:"",
      Account:null,
      Login:null,
      Settings:false,
      JustLoggedIn:true,
      Stats:null,
      TourneyWin:false,
      Leaderboard:false,
      LBStats:null,
      ConfirmBox:null,
      Cheers:[],
      CanCheer: false,
      LEffect:"Force",
      REffect:"Acid",
    };
    this.Set = this.Set.bind(this);
    this.RandomName = this.RandomName.bind(this);
    this.SetName = this.SetName.bind(this);
    this.SendChat = this.SendChat.bind(this);
    this.MakeBet = this.MakeBet.bind(this);
    this.HandleEvent = this.HandleEvent.bind(this);
    this.Login = this.Login.bind(this);
    this.LoggedOut = this.LoggedOut.bind(this);
    this.ChangePassword = this.ChangePassword.bind(this);
    this.AccountFromLogin = this.AccountFromLogin.bind(this);
    this.UpdateStats = this.UpdateStats.bind(this);
    this.OpenLB = this.OpenLB.bind(this);
    God.BS = this;
    Slurs.Init();
    // let max = 0;
    // let tempn = 0;
    // for(let nam of God.Adjectives)
    //   max = Math.max(nam.length,max);
    // for(let nam of God.People)
    //   tempn = Math.max(nam.length,tempn);
    // for(let nam of God.RarePeople)
    //   tempn = Math.max(nam.length,tempn);
    // max += tempn + 1;
    Slurs.MaxName = 20;
    // console.log("MAX: " + max)
    // console.log("COOKIES: " +   JSON.stringify(God.ReadCookies()));
    
    // this.WS = WebSock();
  }

  Set(p,v){
    // console.log("SET: " + p +" / " + v)
    let upd = {};
    upd[p] = v;
    if (p == "ConfirmBox"){
      upd["ToggleBuy"] = false;
    }
    this.setState(upd);
  }

  componentDidMount(){
    // FM.Setup(this);
    // if (!God.Cookies.Account){
    //   let acct = Math.random().toString(36).slice(2) + "" + Math.random().toString(36).slice(2);
    //   God.UpdateCookies("Account",acct);
    //   this.setState({"Account":acct})
    // }
    // else
    //   this.setState({"Account":God.Cookies.Account})
    
    DB.Connect(m=>{
      // console.log("MOD: " + JSON.stringify(m.Connect))
      if(m.Connect)
      {
        this.AccountFromLogin(m.Connect)
      }
      else{
        this.AccountFromCookies();
      }
      this.setState({Model:m,Red:m.Red,Blue:m.Blue,Dist:m.Dist});
    });

    
  }


  AccountFromLogin(info){
    let st = {};
    st.JustLoggedIn = false;
    st.Name = info.name;
    st.Score = info.gold;
    st.Account = info;
    st.Login=null;
    st.Stats = info.stats;
    if (st.Stats && st.Stats.plat != undefined)
      st.Plat = st.Stats.plat;
    if (info.pending == "TourneyWin") st.TourneyWin = true;
    if (info.abils) st.Abilities = JSON.parse(info.abils);
    if (info.auth <= 0)
      st.Muted = true;
    // console.log("BETON: " + info.BetOn + " / " + info.BetAmount);
    if (info.BetOn && info.BetAmount){
      // console.log("BETON")
      st.BetStatus = {Type:"BetStatus",Text:"You bet " + info.BetAmount + " gold on " + info.BetOnName + ".",Class:"",Locked:true,Info:{Who:info.BetOnName,ID:info.BetOn,Amount:info.BetAmount,HTC:"Bets"}}
      st.YourBet = {ID: info.BetOn, Amount: info.BetAmount, Who: info.BetOnName}
    }
    st.CanCheer = info.canCheer;
    this.setState(st);
    // God.TB.Set("Muted",info.muted);
    // console.log("AFL: " + JSON.stringify(info))
    God.TB.SetAccount(info);
    setTimeout(() => {
      // console.log("INFO: " + info.id)
      DB.SendWS({Type:"Hello",ID:info.id});
    }, 100);
  }

  AccountFromCookies(){
    // this.setState({"Login":true});
    // if (God.Cookies.Name){
    //   let score = 10;
    //   this.SetName(God.Cookies.Name);
    //   // this.setState({"Returner":true});
    //   if (God.Cookies.Score)
    //     score = parseInt(God.Cookies.Score);
    //   if (God.Cookies.Abilities){
    //     this.setState({"Abilities":JSON.parse(God.Cookies.Abilities)})
    //   }
    //   if (God.Cookies.LiveBet && God.Cookies.LiveBet != "0"){
    //     score += parseInt(God.Cookies.LiveBet);
    //     God.UpdateCookies("LiveBet",0);
    //     God.UpdateCookies("Score",score);
    //   }
    //   if (God.Cookies.Muted)
    //     this.setState({"Muted":God.Cookies.Muted})
    //   this.setState({"Score":score})
    // }
    // else{
      // console.log("FROM COOKIES")
      let st = this.state.BetStatus;
      // st.Locked = true;
      this.setState({"Confirmed":God.IsLocal,BetStatus:st,JustLoggedIn:true});
      // console.log("JUST LOGGED IN: true / " + this.state.JustLoggedIn);
      this.RandomName();
    // }
  }

  HandleEvent(e){
    // console.log("MSG: " + e.Connections);
    let upd = Events(e,this.state,this.Set);
    // upd.Connections = e.Connections;
    if (God.TB)
      God.TB.Set("Connections",e.Connections);
    
    this.setState(upd);

  }

  Login(info){//username,password){
    // DB.SendLogin('login',username,password,{},e=>{
    DB.SendPost('login',info,e=>{
      // console.log("LOGIN: " + JSON.stringify(e));
      // this.AccountFromLogin(e)
      if (e.username){
        this.AccountFromLogin(e);
      }
      else if (e.Message){
        let login = this.state.Login ? this.state.Login : {};
        login.Message = e.Message;
        this.setState({Login:login});
      }
    },e=>{//login error
      if (!e){
        console.log("LOGIN ERROR X");
        return;
      }
      if (e.status != 200){
        console.log("LOGIN ERROR: " + e.status + " / " + e.statusText);
        return;
      }
    });
  }

  ChangePassword(old,new1,new2,payload){
    if (!old || !new1 || new1 != new2) return;
    let info = {old:old,newpass:new1};
    DB.SendPost('changepw',info,e=>{
      if (payload)payload(e);
    });
  }

  ResetPassword(username,payload){
    if (!username || username == "") return;
    let info = {user:username};
    DB.SendPost('resetpw',info,e=>{
      if (payload)payload(e);
    });
  }

  MakeAcct(info){//username,password,info){
    // console.log("INFO: " + JSON.stringify(info));
    // DB.SendLogin('register',username,password,info,e=>{
    DB.SendPost('register',info,e=>{
      // console.log("register: " + JSON.stringify(e));
      // this.AccountFromLogin(e)
      if (e.username){
        God.BS.AccountFromLogin(e);
      }
      else if (e.Message){
        God.BS.Set("Login",{NewAcct:true,Message:e.Message});
        // let login = this.state.Login ? this.state.Login : {};
        // login.Message = e.Message;
        // this.setState({Login:login});
      }
    },e=>{//login error
      if (!e){
        console.log("REGISTER ERROR X");
        return;
      }
      if (e.status != 200){
        console.log("REGISTER ERROR: " + e.status + " / " + e.statusText);
        return;
      }
    });
  }

  LogOut(e){
    DB.SendGet('logout',e=>{
      // console.log("LOGGING OUT")
      this.LoggedOut();
    },undefined,false);
  }

  SendChat(msg,cheer,leftCheer){
    // console.log("SEND CHAT: " + msg);
    let monName = "";
    let shiny = 0;
    if (this.state[this.state.YourBet.ID]){
      monName = this.state[this.state.YourBet.ID].Name.replaceAll(" ","");
      // who.Class.replaceAll(" ","");
      let altArt = this.state[this.state.YourBet.ID].Tags.AltArt;
      if (altArt != undefined){
        monName = "Alt/"+monName+altArt.Path;
      }
      if (this.state[this.state.YourBet.ID].Tags.Shiny)
        shiny = this.state[this.state.YourBet.ID].Tags.Shiny;
    }
    // let monName = this.state[this.state.YourBet.ID] ? this.state[this.state.YourBet.ID].Name : "";
    let face = this.state[this.state.YourBet.ID] ? this.state[this.state.YourBet.ID].Face : {X:44,Y:70};
    let team = this.state.YourBet.ID ? this.state.YourBet.ID : "Undecided";
    let chat = {Type:"Chat",Text:msg,Who:this.state.Name,Team:team,Score:this.state.Score,Bet:this.state.YourBet.Amount,
      Monster:monName,Face:face,Shiny:shiny};
    if (cheer){
      chat.Cheer = cheer;
      chat.Left = leftCheer;
      this.setState({CanCheer:false})
    }
    chat.SlurLvl = Slurs.Audit(msg);
    // let namaudit = Slurs.AuditName(this.state.Name);
    // if (slurlvl >= 2 || !namaudit){
    //   // God.UpdateCookies("Muted",true);
    //   this.setState({"Muted":true})
    // }
    if (!this.state.Muted && (chat.Text != this.state.LastChat || cheer)){//!slurlvl && && namaudit
      // DB.SendWS(chat,this.state.Account);
      DB.SendPost("SendChat",chat,msg=>{
        // console.log("CHAT: " + JSON.stringify(msg));
        if (msg && msg.Refresh) this.LoggedOut(true);
      });
      this.setState({"LastChat":chat.Text})
    }
    if (chat.SlurLvl)
    {
      let cl = this.state.Chat;
      cl.push(chat);
      this.setState({Chat:this.state.Chat})  
    }
  }

  LoggedOut(login){
    // console.log("LOGGED OUT: " + login);
    localStorage.clear();
    let lgn = login ? {Message:"You have been logged out"} : null;
    this.setState({"Confirmed":false,Account:null,Login:lgn,Score:God.StartMoney});
    this.RandomName();
    God.TB.Set("Account",null);
  }

  MakeBet(who,amt,free){
    // console.log("MAKEBET: " + who + " / "+ amt);
    let bs = this.state.BetStatus;
    bs.Locked = true;
    let nscore = free ? this.state.Score : this.state.Score - amt;
    this.setState({"Score":nscore,BetStatus:bs})
    God.TB.Set("Team",who);
    if (this.state.Account){
      DB.SendPost("SendBet",{Type:"Bet",Amount:amt,BetOn:who},msg=>{
        // console.log("BET: " + JSON.stringify(msg));
        if (msg && msg.Refresh) this.LoggedOut(true);
      });
    }
    else
      DB.SendWS({Type:"Bet",Amount:amt,BetOn:who});
    God.UpdateCookies("Score",nscore);
    God.UpdateCookies("LiveBet",amt);
  }

  SetName(nam){
    // console.log("SET NAME: " + nam)
    this.setState({"Name":nam});
    God.TB.Set("Name",nam);
    God.UpdateCookies("Name",nam);
  }

  ChangeName(name,payload){
    let info = {old:this.props.Name,name:name};
    this.SetName(name)
    DB.SendPost('changename',info,e=>{
      if (payload)payload(e);
    });
  }

  RandomName(){
    let person = undefined;
    for(let n = 0;n < 10;n++){
      let fancy = Math.random() < 0.1;
      // console.log("GOD: " + God.People);
      let entries = God.People.length + (fancy ? God.RarePeople.length : 0);
      let roll = Math.floor(Math.random() * entries);
      // let person = "";
      if (roll < God.People.length)
        person = God.People[roll];
      else{
        roll -= God.People.length;
        person = God.RarePeople[roll];
      }
      person = God.Adjectives[Math.floor(Math.random()*God.Adjectives.length)] + " " + person;
      person = person.replace("- ","-").replace("A E","An E").replace("A A","An A").replace("A I","An I").replace("A O","An O").replace("A U","An U");
      if (person == "Nude Child" || person == "Happy Merchant") person = "????????";
      if (person && person.length <= Slurs.MaxName) break;
    }
    this.SetName(person);
    let abils = God.GetSkills();
    let gold = this.state.Score < God.StartMoney ? God.StartMoney : this.state.Score;
    this.setState({Score:gold,Abilities:abils});
    God.UpdateCookies("Score",gold)
    God.UpdateCookies("Abilities",JSON.stringify(abils));
    // console.log("MONEY: " + God.StartMoney)
  }

  AuditName(){
    setTimeout(() => {
      if (this.state.Account && this.state.Name != this.state.Account.name){
        //Update name in database
        DB.SendPost("NewName",{name:this.state.Name,abils:JSON.stringify(this.state.Abilities)});
        let acct = this.state.Account;
        acct.name = this.state.Name;
        this.setState({Account:acct});
      }
    }, 500);
  }

  UpdateStats(){
    DB.SendGet("GetStats",r=>{
      let upd = {Stats:r};
      if (r.plat != undefined)
        upd.Plat = r.plat;
      this.setState(r);
      // console.log("STATS: " + JSON.stringify(r));
    })
  }

  OpenLB(){
    this.setState({Leaderboard:true});
    DB.SendGet("GetLeaderboard",r=>{this.setState({LBStats:r})})
  }

  ToggleBuy(id){
    // console.log("TOGGLE BUY: " + id)
    if (id == this.state.ToggleBuy)
      this.setState({ToggleBuy:false});
    else
    this.setState({ToggleBuy:id});
  }

  CanBuy(mon){
    return true;
    // return this.state.Plat > 0 && (!mon.Owner || mon.Owner.ID == this.state.Account.id);
  }

  AddCheer(img,left,msg){//Img:"Rose.png",Left:true,ID:1,Offset:0,Text:"You go!"
    if (!img) img = "Rose.png";  
    let chrs = this.state.Cheers;
    let offs = (Math.random() - 0.5) * 40;
    let id = Math.random()*10000;
    chrs.push({Img:img,Left:left,ID:id,Offset:offs,Text:msg});
    this.setState({Cheers:chrs});
    setTimeout(() => {
      chrs = [];
      for(let ch of this.state.Cheers){
        // console.log ("CH: " + ch + " / " + ch.ID + " / " + id + " / " + chrs.length)
        if (ch.ID != id)
          chrs.push(ch);
      }
      this.setState({Cheers:chrs});
    }, 2500);
  }

  render() {
    let lastbet = this.state.Score <= 0 && !this.state.BetStatus.Locked && this.state.Blue != null;
    let pop = this.state.Confirmed && !lastbet && !this.state.Dead && !this.state.Returner && !this.state.Settings
       && !this.state.Leaderboard 
       && !this.state.TourneyWin && (!this.state.Login || this.state.Account) ? null : <div className="TheFade">
      <WelcomeWindow Name={this.state.Name} Set={this.Set} Returner={this.state.Returner} score={this.state.Score} Reroll={this.RandomName} 
        MakeBet={this.MakeBet} Set={this.Set} Dead={this.state.Dead} LastBet={lastbet} Red={this.state.Red} Blue={this.state.Blue}
        Abilities={this.state.Abilities} Register={this.state.Login} Login={this.Login} MakeAcct={this.MakeAcct} 
        Settings={this.state.Settings} Account={this.state.Account} Stats={this.state.Stats} TourneyWin={this.state.TourneyWin}
        Leaderboard={this.state.Leaderboard} LBStats={this.state.LBStats} Plat={this.state.Plat}/> </div>;
    let currentTxt = this.state.Log.length > 0 ? this.state.Log[this.state.Log.length - 1] : null;
    let zoom = this.state.Model ? this.state.Model.Zoom : 1;
    // console.log("REND: "+this.state.BetReturns);
    return <div className="BattleScreen"> {pop}
      <Statsheet who={this.state.Red} arena={this.state.Arena} opponent={this.state.Blue} left={true} />
      <BetsStatus Name={this.state.Name} Totals={this.state.BetTotals} YourBet={this.state.YourBet} Score={this.state.Score}
        Account={this.state.Account} Returns={this.state.BetReturns} Model={this.state.Model}/>
      <BWindow scene={null} left={this.state.Red} right={this.state.Blue} dist={this.state.Dist} Text={currentTxt} 
        Arena={this.state.Arena} Cheers={this.state.Cheers} Zoom={zoom} REffect={this.state.REffect} LEffect={this.state.LEffect}/>
      <Statsheet who={this.state.Blue} arena={this.state.Arena} opponent={this.state.Red} left={false} />
      <ChatWindow log={this.state.Chat} team="Red"/>
      <BLog log={this.state.Log}/>
      <ChatInput Bet={this.state.YourBet} SendChat={this.SendChat} SetName={this.SetName} MakeBet={this.MakeBet} 
        Account={this.state.Account} Status={this.state.BetStatus} Score={this.state.Score}/>
      <BetsBox left={true} Account={this.state.Account} BuyMode={this.state.ToggleBuy=="Red"} Status={this.state.BetStatus}
        Mon={this.state.Red} YourBet={this.state.YourBet} MakeBet={this.MakeBet} Score={this.state.Score} CanCheer={this.state.CanCheer}
        JLI={this.state.JustLoggedIn} Plat={this.state.Plat} Target="Red"/>
      <BetsBox left={false} Account={this.state.Account} BuyMode={this.state.ToggleBuy=="Blue"} Status={this.state.BetStatus}
        Mon={this.state.Blue} YourBet={this.state.YourBet} MakeBet={this.MakeBet} Score={this.state.Score} CanCheer={this.state.CanCheer}
        JLI={this.state.JustLoggedIn} Plat={this.state.Plat} Target="Blue"/>
      <ConfirmBox info={this.state.ConfirmBox} Plat={this.state.Plat}/>
      {/* <ChatWindow left={false} log={this.state.Chat} team="Blue"/> */}
      {/* Status={this.state.BetStatus} Score={this.state.Score} Totals={this.state.BetTotals} YourBet={this.state.YourBet}  */}
      <WS GetMsg={m=>{this.HandleEvent(m)}}/>
      {/* {this.state.Login?<LoginWindow Login={this.Login}/>:null} */}
      {/* <ExtraInfo Connections={this.state.Connections}/> */}
      
    </div>;
  }
}

export default BattleScreen;