import React from 'react';
import './style.css';
import './styleN.css';
import Profile from './Scripts/Profile.js';
import MonsterProfile from './Scripts/MonsterProfile.js';
import TopBar from './Scripts/TopBar.js';
import BattleScreen from './Scripts/BattleScreen.js';
import { Switch, Route,HashRouter  } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <HashRouter >
      <Switch>
          <Route path={`/pc/:playerID`}>
            <Profile/>
          </Route>
          <Route path={`/npc/:monID`}>
            <MonsterProfile/>
          </Route>
          <Route path="/*/*" >
            dead end
          </Route>
          <Route path="/">
            <TopBar/>
            <BattleScreen/>
          </Route>
        </Switch>
        </HashRouter >
      
    </div>
  );
}

// function Nothing(children){

// }

export default App; 
