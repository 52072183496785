import React from "react";
import God from "./God";
// import DB from "./DB.js";

class ChatInput extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Input:"",
      Amount:50,
      Cooldown:false,
    };
    this.onResolve = this.onResolve.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleTyping = this.handleTyping.bind(this);
    this.Login = this.Login.bind(this);
    // console.log("13, 68->" + (13-(13%10)) + " / " + (68-(68%10)));
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevProps.Score != this.props.Score && this.props.Score < this.state.Amount && !this.props.Bet.Who){
      this.setState({"Amount":this.props.Score - (this.props.Score%10)});
    }
  }

  handleKeyPress(e){
    // console.log("HKP: " + e.target.value);
    if (e.key === 'Enter' && !e.shiftKey)
    {
      this.onResolve();
    }
  }

  handleTyping(e){
    // console.log("HT: " + e.target.value);
    if (e.key === 'Enter' && !e.shiftKey)
    {
      this.props.SendChat(this.state.Input)
      return;
    }
    this.setState({Input: e.target.value});
    God.ChatInput = e.target.value;
  }

  onResolve(){
    if (this.state.Cooldown){
      this.setState({ShowCD:true});
      return;
    }
    if (this.state.Name === ""){
      this.props.SetName(this.state.Input);
      this.setState({Name:true})
    }else if (this.state.Input != ""){
      this.props.SendChat(this.state.Input);
      this.setState({Cooldown:true});
      setTimeout(() => { this.setState({Cooldown:false});this.setState({ShowCD:false});} , 4000);
    }
    this.setState({Input: ""}); 
    God.ChatInput = "";
  }
  
  SetAmount(amt){
    this.setState({Amount:amt});
  }

  MakeBet(who,amt){
    if (amt == undefined)
      amt = this.state.Amount;
    // console.log("MAKE BET: " + who + " / "+ this.state.Amount);
    this.props.MakeBet(who,amt);
  }

  
  Login(e,newacct){
    God.BS.Set("Login",{NewAcct:newacct});
  }

  render() {
    // console.log("LOGIN INP: " + (this.props.Login ? this.props.Login.Message : "X"))
    if (this.props.Account == null){
      return <div className="LoginPrompt">
      {/* <div className="LPText">Log-in To Chat: </div> */}
      <div className="LoginButton Prompt" onClick={e=>this.Login(e,false)}>LOGIN</div>
      <div className="LoginButton Prompt" onClick={e=>this.Login(e,true)}>REGISTER</div>
    </div>;
    }
    else if (this.props.Account.auth == 0){
      return <div className="LoginPrompt">
      <div className="LoginInfoMsg">Verify your email & reload to chat</div>
    </div>;
    }

    // if (this.state.Name == ""){
    //     return <div className="Block ChatBar" onKeyPress={e=>this.handleKeyPress(e)}>
    //       <input className="ChatInput" onChange={e => this.handleTyping(e)} value={this.state.Input}/>
    //       <span className="SendButton" onClick={e=>{this.onResolve()}}>Select Name</span>
    //     </div>;
    // }
    let txtclss = "SendButton";
    if (this.state.ShowCD) txtclss += " Invalid";
    return <div className="Block ChatBar" onKeyPress={e=>this.handleKeyPress(e)}>
      <div className="CIBG1"/><div className="CIBG2"/><div className="CIBG3"/><div className="CIBG4"/>
      <div className="SBBG1"/><div className="SBBG2"/><div className="SBBG3"/><div className="SBBG4"/>
      <input className="ChatInput" onChange={e => this.handleTyping(e)} value={this.state.Input} maxLength={95}/>
      <span className={txtclss} onClick={e=>{this.onResolve()}}>Cheer</span>
    </div>;
  }
}

export default ChatInput;