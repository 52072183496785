import React from "react";
import ConfirmBox from "./ConfirmBox.js";
import God from "./God.js";
import Portrait from "./Portrait.js";
import Scrollbar from "./Scrollbar.js";

class Statsheet extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      NameChange:false,
      NameText:"",
      NameOverride:null,
    }
    this.EnableNameChange = this.EnableNameChange.bind(this);
    this.ResolveNameChange = this.ResolveNameChange.bind(this);
  }

  EnableNameChange(e,who){
    this.setState({NameChange:true,NameText:who.Name});
  }
  ResolveNameChange(res){
    console.log("RESOLVE NC");
    this.setState({NameChange:false,NameOverride:res.Name});
  }
  
  MakeElem(prop,title,max,highlight){
    let finalP = prop;
    let val = undefined;
    if (typeof prop == "string"){
      val = this.props.who[prop];
    }else{
      val = this.props.who;
      for(let p of prop)
        {
          if (val)
            val = val[p];
          finalP = p;
        }
    }
    if (!title)
      title = finalP + ": ";
    if (val === undefined)
      val = "?";
    let cl = 'Stat';
    if (highlight)
      cl += ' Highlight';
    if (!max)
      return <span className={cl} key={prop + title}>{title}{val}</span>;
    if (max === true)
      max = 'Max' + prop;
    return <span className={cl} key={prop + title}>{title}{val}/{this.props.who[max]}</span>;
  }

  MakeHeader(title,n){
    if (!n) n = "";
    return <span className='SSHeader' key={"H " + title+n}><img className="SSHeaderDots" src="/Art/UI/SSHeaderDots.png"/>{' '}{title}</span>;
  }

  render() {
    let who = this.props.who;
    // console.log(JSON.stringify(who));
    let lr = this.props.left ? "Left TeamRed" : "Right TeamBlue";
    // let pos = this.props.left ? {};
    let dims = [3.31,11.78,15.375,50.11,70];
    if (this.props.profile){
      // dims[1] -= 5;
      dims[0] += 10;
      dims[2] += 5;
      dims[3] += 25;
      dims[4] = 100;
    }
    let style = {top:dims[1]+'vh',width:dims[2]+'vw',height:dims[3]+'vh',fontSize:dims[4]+"%"};
    let lrtxt = this.props.left ? 'left' : 'right';
    style[this.props.left ? "left" : "right"] = dims[0]+'vw';
    let bord = 0.375;
    let bstyle = {top:'calc('+dims[1]+'vh - '+bord+"vw)",width:(dims[2]+bord*2)+'vw',height:'calc('+dims[3]+'vh + ' + (bord*2)+'vw)'};
    bstyle[lrtxt] = (dims[0]-bord)+'vw';
    let cwid = 0.89 ;
    let chei = 1.8;
    let cwidD = 0.87 ;
    let cheiD = 0.747;
    let sign = this.props.left ? 1 : -1;
    let corners = [{top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign*-1)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign*-1)+")"}]
    corners[0][lrtxt] = "calc("+dims[0]+"vw - "+(cwid*1.05)+"vw)";
    corners[1][lrtxt] = "calc("+(dims[0]+dims[2])+"vw - "+(cwid*2.95)+"vw)"; 
    corners[2][lrtxt] = "calc("+dims[0]+"vw - "+(cwidD*2.31)+"vw)";
    corners[3][lrtxt] = "calc("+(dims[0]+dims[2])+"vw - "+(cwidD*2.95)+"vw)";
    let decor = (<div>
        <div className={"SSBorder " +lr} style={bstyle}/>
        <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={corners[0]}/>
        <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={corners[1]}/>
        <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={corners[2]}/>
        <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={corners[3]}/>
        {/* <img className={"SSIcon BG " +lr} src="/Art/UI/StatIconBG.png"/>
        <img className={"SSIcon FG " +lr} src="/Art/UI/StatIconFG.png"/> */}
        {(who ? <Portrait Who={who} Class={'SSIcon ' + lr} Left={this.props.left} Profile={this.props.profile}/> : null)}
      </div>);
    let content = null;
    
    if (who){// return <div><div className={"SSBorder " +lr}/><div className={"Block Statsheet " + lr}></div></div>;

      let elems = [];

      if (who.Owner){
        elems.push(<a href={"#/pc/"+who.Owner.ID} key="OWNERLINK" target="_blank">{this.MakeHeader("ADOPTED BY " + who.Owner.Name.toUpperCase())}</a>);
        elems.push(<div className='Stat Wide' key={"own"+elems.length}>{who.Class}</div>);
      }
      // elems.push(God.FloatBreak());
      elems.push(this.MakeHeader("ATTRIBUTES"));
      elems.push(this.MakeElem(['Stats','STR']));
      elems.push(this.MakeElem(['Stats','DEX']));
      elems.push(this.MakeElem(['Stats','CON']));
      elems.push(this.MakeElem(['Stats','INT']));
      elems.push(this.MakeElem(['Stats','WIS']));
      elems.push(this.MakeElem(['Stats','CHA']));
      elems.push(this.MakeElem('HP','HP: ',true,true));
      elems.push(this.MakeElem('AC',undefined,undefined,true));
      let maxspd = 0;
      let spdtyp = "Spd";
      for(let sp in who.Movement){
        let actual = who.Movement[sp];
        if (this.props.arena && this.props.arena.CantMove && this.props.arena.CantMove[sp] != undefined)
          actual *= this.props.arena.CantMove[sp];
        if (actual > maxspd)
        {
          maxspd = actual;
          // spdtyp = sp;
        }
      }
      if (spdtyp != "")
        elems.push(<span key={"mvtype " + elems.length} className='Stat Highlight'>{spdtyp}: {maxspd}ft</span>);
      // elems.push(God.FloatBreak());
      if (who.TStats){
        let list = "";
        for(let w of who.TStats.Wins){
          if (list != "") list += ", ";
          list += w;
        }
        if (list != ""){
          elems.push(this.MakeHeader("WINS",elems.length));
          elems.push(<span className='Stat Wide' key={"L"+elems.length}>{list}</span>);
        }
        if (who.TStats.KilledBy){
          let killer = who.TStats.KilledBy.Owner ? <a className="ProfLink" href={"#/npc/"+who.TStats.KilledBy.Owner.PetID} target="_blank">{who.TStats.KilledBy.Name}</a> : <span>{who.TStats.KilledBy.Name}</span>
          elems.push(this.MakeHeader("KILLED BY",elems.length));
          elems.push(<span className='Stat Wide' key={"L"+elems.length}>{killer}</span>);
        }
      }
      let ModList = ["Immune","Resist","Vulnerable","Absorb"];
      let any = false;
      for (let mod of ModList){
        if (who[mod]){
          let str = "";
          let pierce = "";
          for (let i in who[mod]){
            if (!who[mod][i].Show) continue;
            let pierced = who[mod][i].Magical !== undefined && this.props.opponent && this.props.opponent.Tags["Magic Attacks"] !== undefined;
            // if (mod == "Resist" && i == "Bludgeoning")
            //   console.log("MODL: " + mod + " / " + pierced + " / " + (this.props.opponent ? this.props.opponent.Tags["Magic Attacks"] : "NONE"))
            // console.log("I: " + i + " / " + who[mod][i].Show);
            if (!pierced)
              str += (str == "" ? "": ", ") + i;
            else
              pierce += (pierce == "" ? "": ", ") + i;
          }
          // console.log("MOD: " + who.Name + " / " + mod + " / " + str + " / " + JSON.stringify(who[mod]));
          if (str != "" || pierce != ""){
            let strpie = <span>{str}{str != "" && pierce != "" ? ", " : ""}<span className="PiercedResist">{pierce}</span></span>
            elems.push(this.MakeHeader(mod.toUpperCase()));
            elems.push(<span className='Stat Mod' key={"mod"+elems.length}>{strpie}</span>)
            any = true;
          }
        }
      }
      // if (any) elems.push(God.FloatBreak());
      // elems.push(<u key="acts u" className='Stat Wide'>Actions</u>);
      elems.push(this.MakeHeader('ACTIONS'));
      // let acts = "";
      let alists = ["Actions","BActions"];
      for(let lis of alists){
        if(!who[lis]) continue;
        for(let a in who[lis]){
          // console.log("ACTS: " + a.Name + " / " + JSON.stringify(a.Tags));
          if (who[lis][a].Tags.DontShow) continue;
          let tag = "";
          if (who[lis][a].Priority <= 0) tag = " Involuntary";
          if (who[lis][a].AType == "Bonus") tag = " Bonus";
          if (who[lis][a].Tags.Reaction) tag = " Reaction";
          if (who[lis][a].Tags.Legendary) tag = " Legendary";
          // console.log("REACT: " + who[lis][a].Name + who[lis][a].Tags.Reaction )
          if (who[lis][a].MaxUses && !who[lis][a].Uses) tag = " Inactive";
          elems.push(<div className='Stat Wide' key={"act"+elems.length}>
            <span className={'ActName' + tag}>{who[lis][a].Name+": "}</span>
            <span className='ActDesc'>{God.Act2Str(who[lis][a])}</span>
            </div>)
          // if (acts != "")
          //   acts += ", ";
          // acts += who[lis][a].Name;
        }
      }
      // elems.push(<span className='Stat Wide' key={"acts"}>Actions: {acts}</span>);
      // elems.push(God.FloatBreak());

      any = false;
      let tempelems = [];
      for(let con in who.Conditions){
        if (!who.Conditions[con])
          continue;
        if (!any){
          // elems.push(<u key="conds u" className='Stat Wide'>Conditions</u>);
          elems.push(this.MakeHeader('CONDITIONS',elems.length));
          any = true;
        }
        let cl = 'Stat Small';
        let condesc = who.Conditions[con].Desc;
        if (who.CInfo[con] && who.CInfo[con].Desc)
          condesc = who.CInfo[con].Desc;
        let desc = condesc ? <div className="Stat Small PopOut">{condesc}</div> : null;
        if (condesc) cl += ' CanPop';
        if (who.Conditions[con].Feat){
          cl += ' Feat';
          elems.push(<span className={cl} key={"con"+con+elems.length}>{con}{desc}</span>);
        }
        else
          tempelems.push(<span className={cl} key={"con"+con+tempelems.length}>{con}{desc}</span>);
      }
      elems.push(<div key={"Tempel"+elems.length}>{tempelems}</div>)


      
      let desc = God.SizeToString(who.Size);
      let descpre = null;
      let ssstats = "SSStats";
      for(let tag in who.Tags)
        if (who.Tags[tag].Shown){
          desc += " " + tag;
          if (tag == "of"){
            descpre = <div>{desc}</div>;
            desc = "";
            ssstats += " Swarm";
          }
        }
      if (this.props.profile){
        ssstats += " ProfilePage";
      }
      // elems.push(this.MakeElem('Init'));
      // elems.push(this.MakeElem('Acc'));
      // elems.push(this.MakeElem('Dmg','Dmg: 1d'));
      // console.log("OWNER: " + JSON.stringify(who.Owner));
      let shownName = this.state.NameOverride ? this.state.NameOverride : who.Name;
      let namezone = who.Owner && who.Owner.PetID ? <a className="ProfLink" href={"#/npc/"+who.Owner.PetID} target="_blank">{shownName}</a> : <span>{shownName}</span>;
      let link = (who.Link ? who.Link : "https://5thsrd.org/gamemaster_rules/monsters/" + who.Class.replaceAll("-","_").replaceAll(" ","_").toLowerCase());
      let buytoggle = God.BS && God.BS.CanBuy(who) ? <div className='SSLink SSBuyToggle' onClick={e=>{God.BS.ToggleBuy(who.ID)}}><img className='SSLinkImg' src="Art/UI/SSToggle.png"/></div>:null;
      let namechange = this.props.profile && this.props.yourPet ? <div className='SSLink SSBuyToggle' onClick={(e)=>{console.log("HI");this.EnableNameChange(e,who);}}><img className='SSLinkImg' src="Art/UI/SSToggle.png"/></div>:null;
      let nameBox = this.state.NameChange ? <ConfirmBox  OnConfirm={(res)=>{this.ResolveNameChange(res)}} info={{Type:"NamePet",Message:"Rename Monster -- Cost: Free",MonID:who.Owner.PetID,Name:who.Name}}/> : null;
      content = (<div><a className='SSLink' href={link} target="_blank"><img className='SSLinkImg' src="/Art/UI/SSLink.png"/></a>{buytoggle}{namechange}
        <span className={'SSName' + (this.props.profile ? ' ProfileName' : '')}>{namezone}</span>
        <span className='SSInfo'>{descpre}{desc}</span>
        <span className='SSInfo'>CR: {God.CRToString(who.CR)}</span>
        <div className={ssstats}>
          {/* <Scrollbar Offset={0} className="SSScroll" Invert={true}> */}
            {elems}
          {/* </Scrollbar> */}
        </div>
        {nameBox}
        </div>);
    }
    return <div>{decor}
    <div className={"Block Statsheet " + lr} style={style}>  
      {content}
    </div></div>;
  }
}

export default Statsheet;