import React from "react";
import God from "./God.js";
import Banner from "./Banner.js";
import Username from "./Username.js";
// import ChatInput from "./ChatInput.js";
import Scrollbar from "./Scrollbar.js";

class ChatWindow extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Imgs:{},
    };
    this.updateCanvas = this.updateCanvas.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    let imgs = this.state.Imgs;
    let start = Math.max(0,this.props.log.length-100);
    for(let n = start;n < this.props.log.length;n++){
      let mon = this.props.log[n].Monster ? this.props.log[n].Monster : "/UI/NonBettor";
      mon = '/Art/'+mon.replaceAll(" ","")+".png";
      if (!imgs[mon]){
        imgs[mon] = {Img:new Image(),Face:{X:this.props.log[n].Face ? this.props.log[n].Face.X : 44,Y:this.props.log[n].Face ? this.props.log[n].Face.Y : 70}};
        let self = this;
        imgs[mon].Img.onload = function() {
          self.updateCanvas(mon);
        };
      }
      imgs[mon].Img.src = mon;
    }
  }
  updateCanvas(img) {
    let cnvs = document.getElementsByClassName("CIPortC " +img);
    for (let i = 0; i < cnvs.length; i++) {
      let pic = this.state.Imgs[img];
      const ctx = cnvs[i].getContext('2d');
      ctx.imageSmoothingEnabled = false;
      let ftemp = pic.Face;
      let face = {X:ftemp.X+(ftemp.X/10),Y:ftemp.Y-2};
      let zoom = 3;
      ctx.clearRect(0,0,100,100);
      ctx.drawImage(pic.Img,(50-zoom*face.X),(50-zoom*face.Y),100*zoom,100*zoom);
      // ctx.drawImage(pic.Img,0,0,100,100);
    }
    // let who = this.props.Who;
    // const ctx = this.refs.canvas.getContext('2d');
    // ctx.imageSmoothingEnabled = false;
    // let ftemp = who.Face ? who.Face : {X:44,Y:70};
    // let face = {X:ftemp.X+(ftemp.X/10),Y:ftemp.Y-2};
    // let zoom = 5;
    // ctx.drawImage(img,(50-zoom*face.X),(50-zoom*face.Y),100*zoom,100*zoom);
    // ctx.drawImage(img,(50-zoom*face.X),(50-zoom*face.Y),100*zoom,100*zoom);
}
  
  render() {
    let size = [54.44,58.05,23.4375,38.12,0.1875];
    let styleA = {left:size[0]+'vw',top:'calc('+size[1]+'vh - '+size[4]+'vw)',width:size[2]+'vw',
      height:'calc('+size[3]+'vh + '+(size[4]*2)+'vw)'};
    let cwid = 2.6875/2;
    let cwid2 = 1.5/2;
    let corners = [{left:"calc("+size[0]+"vw - "+(cwid2*0.63)+"vw)",top:"calc("+size[1]+"vh - "+(size[4])+"vw)"},
      {left:"calc("+(size[0]+size[2])+"vw - "+(cwid*0.34)+"vw)",top:"calc("+size[1]+"vh - "+(cwid*0.595 + size[4])+"vw)",transform:"scaleX(-1)"},
      {left:"calc("+(size[0])+"vw - "+(cwid2*0.6)+"vw)",top:"calc("+(size[1]+size[3]-0.16)+"vh - "+(cwid2*1.42 + size[4])+"vw)"},
      {left:"calc("+(size[0]+size[2])+"vw - "+(cwid2*1.69)+"vw)",top:"calc("+(size[1]+size[3]-0.16)+"vh - "+(cwid2*1.42 + size[4])+"vw)",transform:"scaleX(-1)"}]


    let entries = [];
    let left = true;
    // console.log(JSON.stringify(this.props.log));
    let start = Math.max(0,this.props.log.length-100);
    for(let n = start;n < this.props.log.length;n++){
      // console.log(JSON.stringify(this.props.log[n]));
      if (this.props.log[n].Team == 'Red') left = true;
      else if (this.props.log[n].Team == 'Blue') left = false;
      let lr = left ? " Left" : " Right";
      // if (this.props.log[n].Team !== this.props.team) continue;
      let nam = this.props.log[n].Who;// + ": ";// + this.props.log[n].Bet + "g on " + this.props.log[n].Monster;
      let id = this.props.log[n].ID;
      // console.log("MSG: " + JSON.stringify(this.props.log[n]))
      // if (nam == "Happy Merchant") continue;
      let mon = this.props.log[n].Monster ? this.props.log[n].Monster : "/UI/NonBettor";
      mon = '/Art/'+mon.replaceAll(" ","")+".png";
      let portStyle = {};
      let face = {X:this.props.log[n].Face ? this.props.log[n].Face.X : 44,Y:this.props.log[n].Face ? this.props.log[n].Face.Y : 70};
      if (left) face.X = 100-face.X;
      portStyle.left = 1+'vw';//(face.X /-8+3)+'vw';//-3.5
      portStyle.top = 1+'vw';//((face.Y) /-8)+4+'vw';//-5, 70
      // let clips = this.props.Left ? [face.Y-13.25,face.X-18,91.75-face.Y,92.5-face.X] : [face.Y-13.25,85-face.X,91.75-face.Y,face.X-9.5];
      let xoffs = this.props.log[n].Face ? this.props.log[n].Face.X : 44;
      let yoffs = face.Y;
      if (left) {
        face.X += 3;
        xoffs += 4;
      }
      else{
        face.X += 4;
        xoffs += 4;
      }
      let cltxt = 'circle(0.95vw at '+xoffs+'% '+yoffs+'%)'
      portStyle['clipPath'] = cltxt;
      portStyle.transform = 'translate(-'+face.X+'%, -'+face.Y+'%)';
      if (left) portStyle.transform += 'scaleX(-1)';
      // portStyle.transform = 'translate(25%, 0%)';
      // portStyle.backgroundColor = 'red';
      let rank = "";
      if (this.props.log[n].Rank >= 4) rank = " Mod";
      else if (this.props.log[n].Rank == 3) rank = " MonthDonor";
      else if (this.props.log[n].Rank == 2) rank = " Donor";

      let cheer = this.props.log[n].Cheer ? <img className="ChatCheer" src={"/Art/Items/"+this.props.log[n].Cheer}/> : null;
      let txt = this.props.log[n].Text ? <span className="ChatText">{cheer}{this.props.log[n].Text}{cheer}</span> 
        : <span className="ChatText">{cheer}{cheer}{cheer}</span>
      let cstyle = {};
      if (this.props.log[n].Shiny){
        cstyle.filter = "hue-rotate("+this.props.log[n].Shiny+"deg)"
      }

      let coin = this.props.log[n].Bet > 0 ?<span>{" "}<img className="ChatCoin" src="/Art/UI/BetCoin.png"/>
        <span className="ChatBet">{God.MoneyAbbr(this.props.log[n].Bet)}{" "}</span></span>:null;
      entries.push(<div className={"ChatEntry Team" + this.props.log[n].Team+lr} key={entries.length}>
        <div className={"CIHolder Team" + this.props.log[n].Team}><div className={"ChatIcon Team" + this.props.log[n].Team+lr}>
          <div className={"ChatIcon FG Team" + this.props.log[n].Team+lr}/>
          <canvas className={"CIPortC " +mon+lr} width="100" height="100" style={cstyle}/>
          {/* <img src={mon} className={"CIPort"+lr} style={portStyle}/> */}
        </div></div>
        <div className={"CTHolder Team" + this.props.log[n].Team+lr}>
          <div className={"ChatName Team" + this.props.log[n].Team + rank}><Username name={nam} id={id}/>{coin}</div>
         {txt}
        </div>
      </div>);
      if (entries.length > 100) break;
    }
    // let lr = this.props.left ? "Left" : "Right";
    // lr += " Team" + this.props.team;
    return <div className={"Block ChatWindow"}>  
      <img src="Art/UI/LogTopI.png" className="LogTopI" style={corners[0]}/>
      <img src="Art/UI/LogTop.png" className="LogTop" style={corners[1]}/>
      <img src="Art/UI/LogBot.png" className="LogBot" style={corners[2]}/>
      <img src="Art/UI/LogBot.png" className="LogBot" style={corners[3]}/>
      <Banner Left={55.44} Top={58.79} Width={21.5} Text={"CHEERS & JEERS"}/>
      <div className="BorderGap CLL"/><div className="BorderGap CLR"/>
      <div className="CWBorder"/><div className="CWBorder2"/><div className="CWBG"/>
      <div className="CWInner"> <Scrollbar Offset={4} className="CWScroll" Invert={true}>{entries}</Scrollbar></div>
     
    </div>;
  }
}

export default ChatWindow;