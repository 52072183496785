import React, { useState } from 'react';
import {useParams} from 'react-router-dom';
import DB from "./DB.js";
import God from './God.js';
import MIcon from './MIcon.js';
import Statsheet from "./Statsheet.js";


function MonsterProfile(){
    let { monID } = useParams();
    const [info, setInfo] = useState(null);
    const [yourPet, setYourPet] = useState(null);
    if (info == null){
      setInfo(1);
      setYourPet(false);
      DB.SendQuery("GetPet","id",monID,r=>{
        setInfo(r.Monster);
        setYourPet(r.YourPet);
        // console.log("STATS: " + JSON.stringify(r));
      })
      return <div>loading...</div>
    }
    if (info == 1) return <div>Loading...</div>
    if (!info) return <div>error</div>

    
    return (<div className="ProfileHolder">
      <Statsheet who={info} arena={{}} left={true} profile={true} yourPet={yourPet} />
      <MIcon who={info} left={false} profile={true}/> 
      {/* <img className="MonProfileArt" src={God.GetArtURL(info)}/> */}
    </div>);
}

export default MonsterProfile; 