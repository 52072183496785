import God from "./God.js";
import Slurs from "./Slurs.js";

let Events = function(e,state,set){
  let upd = {};
  if (e.Text && e.Type !== "Chat"){
    let log = God.TwoState(upd,state,"Log");
    log.push(e);
    upd["Log"]=log;
  }
  let htwipe = true;
  
  // console.log("EV: " + e.Type);
  switch(e.Type){
    
    case "Set":{
      let prop = e.Info && e.Info.Prop ? e.Info.Prop : e.Who;
      // console.log("SET: " + prop + " / " + JSON.stringify(e))
      upd[prop] = e.Info && e.Info.Update ? e.Info.Update : God.TwoState(upd,state,"Model")[prop];
      if (prop == "Blue" && state.Blue == null  && upd[prop] != null)
        upd["BetStatus"] = {Locked: false};
      break;
    }
    
    case "Update":{
      // console.log("UPDATE: " + e.Who + " / " + e.Info + " / " + JSON.stringify(e))
      let who = e.Who;
      let thing = God.TwoState(upd,state,who);//state[who];
      if (!thing)break;
      let prop = e.Info.Prop;
      thing[prop] = e.Info && e.Info.Update ? e.Info.Update : God.TwoState(upd,state,"Model")[who][prop];
      // let upd = {};
      upd[who] = thing;
      break;
    }
    case "Add":{
      let who = e.Who;
      let thing = God.TwoState(upd,state,who);//state[who];
      let prop = e.Info.Prop;
      thing[prop] = e.Info.Added;
      upd[who] = thing;
      break;
    }
    case "NewBattle":{
      upd["JustLoggedIn"]=false;
      upd["Red"] = null;
      upd["Blue"] = null;
      // upd["Arena"] = null;
      upd["YourBet"] = {};
      upd["BetReturns"] = {};
      // upd["BetStatus"] = {Locked: false};
      upd["Dist"] = "";
      if (!God.Settings.Muted) God.TB.PlaySound();
      if (God.Cookies.LiveBet && God.Cookies.LiveBet != "0"){
        let score = God.TwoState(upd,state,"Score");
        upd["Score"] = parseInt(score) + parseInt(God.Cookies.LiveBet);
        God.UpdateCookies("Score",score);
        God.UpdateCookies("LiveBet",0);
      }

      break;
    }
    case "Sync":{
      upd["Model"] = e.Info;
      // console.log("MODEL: " + JSON.stringify(e.Info));
      break;
    }
    case "Connect":{
      upd["Model"] = e.Info;
      let status = God.TwoState(upd,state,"BetStatus");
      status.Locked = e.Info.BetsLocked;
      // console.log("BETSLOCKED: " + e.Info.BetsLocked)
      upd["BetStatus"] = status;
      upd["BetTotals"] = e.Info.BetOdds;
      upd["Arena"] = e.Info.Arena;
      // console.log("FEAT: " + e.Info.Blue.Feat)
      break;
    }
    case "Chat":{
      htwipe = false;
      if (!e.Who)
        break;
      let slurlvl = Slurs.Audit(e.Text);
      let namaudit = Slurs.AuditName(e.Who);
      let txt = e.Text 
      if (slurlvl || !namaudit)
        break;
      if (e.Cheer){
        txt = txt ? txt : "";
        txt = txt.length > 30 ? txt.substr(0,30) : txt;
        God.BS.AddCheer(e.Cheer,e.Left,txt);
      }
      if (txt || e.Cheer){
        
        let log = God.TwoState(upd,state,"Chat");
        log.push(e);
        upd["Chat"]=log;
      }
      
      break;
    }
    case "Winnings":{
      // console.log("WINNINGS: " + JSON.stringify(e));
      let score = God.TwoState(upd,state,"Score");
      if (e.Amount > 0)
        score += e.Amount + e.Bet;
      if (e.GSafety) score = e.GSafety;
      let won = e.Amount > 0;
      upd["BetStatus"] = {Text:"You "+(won?"won ":"lost ") + (e.Amount + e.Bet) +" gold!",Class:(won?"GoodText":"BadText"),Locked:true};
      upd["Score"] = score;
      God.TB.Set("Team",null);
      if (God.TwoState(upd,state,"LastBet"))
        {
          upd["LastBet"] = false;
          if (e.Amount <= 0)
            upd["Dead"] = true;
        }
      God.UpdateCookies("Score",score);
      God.UpdateCookies("LiveBet",0);
      htwipe = false;
      break;
    }
    case "BetStatus":{
      // console.log("BETSTATUS EVENT:" + JSON.stringify(e))
      upd["BetStatus"] = e;
      if (e.Info)
        upd["YourBet"] = e.Info;
      break;
    }
    case "StartRound":{
      // console.log("START ROUND")
      upd["BetStatus"] = {Locked: true};
      break;
    }
    case "BetTotals":{
      upd["BetTotals"] = e.Info;
      // console.log("BETTOTL " + JSON.stringify(e));
      upd["BetReturns"] = e.Real;
      upd["BetStatus"] = {Text: e.BetText,Locked:state.BetStatus.Locked};
      break;
    }
    case "UpdateBets":{
      htwipe = false;
      upd["Dist"] = God.TwoState(upd,state,"Dist");;
      break;
    }
    case "RoundComplete":{
      upd["YourBet"] = {};
      break;
    }
    case "Anim":{
      htwipe = false;
      //Just does an animation
      break;
    }
    case "SmitedByGod":{
      htwipe = false;
      // console.log("SMITED BY GOD")
      upd["Muted"] = true;
      God.UpdateCookies("Muted",true);
      break;
    }
    case "TourneyWin":{
      htwipe = false;
      upd["TourneyWin"] = true;
      upd["Score"] = God.MinGold;
      let stats = God.TwoState(upd,state,"Stats");
      stats.plat = e.Plat;
      stats.totalplat = e.TotalPlat;
      upd["Plat"] = e.Plat;
      break;
    }
    case "CheerCooldown":{
      upd.CanCheer = true;
      break;
    }
    default:{
      if (e.Type)
        console.log("Unhandled Event: " + e.Type);
      break;
    }
  }
  // console.log("MISS: " + e.Text)
  if (e.Text && htwipe && (!e.Info || e.Info.WipeHT !== false))
  {
    let red = God.TwoState(upd,state,"Red");
    let blue = God.TwoState(upd,state,"Blue");
    let arena = God.TwoState(upd,state,"Arena");
    if (red){
      if (!e.Info || e.Info.WipeHT !== false){
        red.Vis.HeadText = null;
        red.Vis.Roll = null;
        red.Vis.PreRoll = null;
        red.Vis.HeadTextCl = null;
      }
    }
    if (blue){
      if (!e.Info || e.Info.WipeHT !== false){
        blue.Vis.HeadText = null;
        blue.Vis.Roll = null;
        blue.Vis.PreRoll = null;
        blue.Vis.HeadTextCl = null;
      }
    }
    upd["Red"] = red;
    upd["Blue"] = blue;
    upd["Arena"] = arena;
    
  }
  // console.log("EV: " + e.Who + ' / ' + JSON.stringify(e))
  if (e.Who && e.Info && e.Info.Event){
    let who = e.Who;
    // console.log("EVENT: " + e.Who + " / " + e.Info.Event)
    if (who == "Red") upd["LEffect"] = e.Info.Event;
    if (who == "Blue") upd["REffect"] = e.Info.Event;
  }
  if (e.Who && e.HeadText){
    let who = e.Who;
    let thing = God.TwoState(upd,state,who);//state[who];
    if (thing){
      thing.Vis.HeadText = e.HeadText;
      thing.Vis.Roll = e.Info ? e.Info.Roll : null;
      // thing.Vis.PreRoll = e.Info ? e.Info.PreRoll : null;
      // if (e.Info && e.Info.PreRollTarg){
      //   let prtarg = God.TwoState(upd,state,thing.Target);
      //   prtarg.Vis.PreRoll = e.Info.PreRollTarg;
      // }
      if (e.Info && e.Info.HTC)
        thing.Vis.HeadTextCl = e.Info.HTC;
      else
        thing.Vis.HeadTextCl = '';
      if (e.Info)
        {
          if (e.Info.Shake){
            thing.Vis.Shake = true;
            let timer = e.Info.Shake === true ? 500 : e.Info.Shake * 1000;
            // console.log("TIMER TIME: " + timer + " / " + e.Info.Shake);
            setTimeout(() => {
              thing = state[who];
              if (!thing)
                return;
              thing.Vis.Shake = false;
              let upd = {};
              upd[who] = thing;
              set(upd); } , timer);
          }
          if (e.Info.Anim){
            thing.Vis.Anim = e.Info.Anim;
            let timer = 2000;
            // console.log("TIMER TIME: " + timer + " / " + e.Info.Shake);
            setTimeout(() => {
              thing = state[who];
              if (!thing)
                return;
              if (thing.Vis.Anim == e.Info.Anim){
              thing.Vis.Anim = undefined;
              let upd = {};
              upd[who] = thing;
              set(upd); }} , timer);
          }
          if (e.Info.SSAnim){
            // console.log("SSANIM: " + e.Info.SSAnim)
            thing.Vis.SSAnim = e.Info.SSAnim;
            let timer = 2000;
            // console.log("TIMER TIME: " + timer + " / " + e.Info.Shake);
            setTimeout(() => {
              thing = state[who];
              if (!thing)
                return;
              if (thing.Vis.SSAnim == e.Info.SSAnim){
              thing.Vis.SSAnim = undefined;
              let upd = {};
              upd[who] = thing;
              set(upd); }} , timer);
          }
          if (e.Info.OAnim){
            let targID = who == "Blue" ? "Red" : "Blue";
            let targ = God.TwoState(upd,state,targID);
            targ.Vis.Anim = e.Info.OAnim;
            let timer = 1000;
            upd[targID] = targ;
            // console.log("TIMER TIME: " + timer + " / " + e.Info.Shake);
            setTimeout(() => {
              targ = state[targID];
              if (!targ)
                return;
              // console.log("ANIM CHECK: " + targ.Vis.Anim + " / " + e.Info.OAnim)
              if (targ.Vis.Anim == e.Info.OAnim){
                targ.Vis.Anim = undefined;
              let upd = {};
              upd[targID] = targ;
              set(upd); }} , timer);
          }
          if (e.Info.OSSAnim){
            // console.log("OSSANIM: " + e.Info.OSSAnim)
            let targID = who == "Blue" ? "Red" : "Blue";
            let targ = God.TwoState(upd,state,targID);
            targ.Vis.SSAnim = e.Info.OSSAnim;
            let timer = 2000;
            // console.log("TIMER TIME: " + timer + " / " + e.Info.Shake);
            setTimeout(() => {
              targ = state[targID];
              if (!targ)
                return;
              if (targ.Vis.SSAnim == e.Info.OSSAnim){
                targ.Vis.SSAnim = undefined;
              let upd = {};
              upd[targID] = targ;
              set(upd); }} , timer);
          }
        }
      // let upd = {};
      upd[who] = thing; 
    }
  }
  if (e.Info && e.Info.SafeSync){
    let rVis = state.Red ? state.Red.Vis : {};
    let bVis = state.Blue ? state.Blue.Vis : {};
    upd.Red = God.TwoState(upd,state,"Model")["Red"];
    upd.Blue = God.TwoState(upd,state,"Model")["Blue"];
    upd.Arena = God.TwoState(upd,state,"Model")["Arena"];
    if (upd.Red)  upd.Red.Vis = rVis;
    if (upd.Blue) upd.Blue.Vis = bVis;
    upd.Dist = God.TwoState(upd,state,"Model")["Dist"];
  }
  let moneyscore = God.TwoState(upd,state,"Score");
  if (moneyscore < God.MinGold)
    upd.Score = God.MinGold;

  // console.log("ZOOM: " + (state.Model  ? state.Model.Zoom : "X") + " / " + (upd.Model  ? upd.Model.Zoom : "X"));
  return upd;
}

export default Events;