import React from "react";

class AIcon extends React.Component {
  
  render() {
    if (!this.props.arena)
      return <span/>;
    let style = {};
    if (this.props.arena.Color){
      let c = "rgb("+this.props.arena.Color.R+","+this.props.arena.Color.G+","+this.props.arena.Color.B+")";
      style["color"] = c;
    }
    return <div className={"AIcon"} style={style}>  
      {this.props.arena.Name}
    </div>;
  }
}

export default AIcon;