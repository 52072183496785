import React, { useState } from 'react';
import {useParams} from 'react-router-dom';
import DB from "./DB.js";
import God from "./God.js";
import Statsheet from "./Statsheet.js";


function Profile(){
    let { playerID } = useParams();
    const [info, setInfo] = useState(null);
    const [selectPet,setSelectPet] = useState(null);
    if (info == null){
      setInfo(1);
      DB.SendQuery("GetProfile","id",playerID,r=>{
        setInfo(r);
        // console.log("STATS: " + JSON.stringify(r));
      })
      return <div>loading...</div>
    }
    if (info == 1) return <div>Loading...</div>
    // let playerID = "abc";
    if (!info) return <div>error</div>

    let rank = "Player";
    if (info.rank >=4) rank = "Admin";
    else if (info.rank ==3) rank = "Active Patron";
    else if (info.rank ==2) rank = "Inactive Patron";

    let winp = Math.round(info.wins / info.bets * 100);
    let drawp = Math.round(info.draws / info.bets * 100);
    let lossp = 100 - winp - drawp;
    

    let dims = [13.31,11.78,20.375,75.11];
    let style = {top:dims[1]+'vh',width:dims[2]+'vw',height:dims[3]+'vh',left:dims[0]+'vw',fontSize:"100%"};
    let style2 = {top:dims[1]+'vh',width:dims[2]+'vw',height:dims[3]+'vh',right:dims[0]+'vw',fontSize:"100%"};
    let bord = 0.375;
    let mOffset = 37.5-(dims[2]/2+bord);
    let styleM = {top:dims[1]+'vh',width:dims[2]+'vw',height:dims[3]+'vh',left:(dims[0]+mOffset)+'vw',fontSize:"100%"};
    // let lrtxt = 'left';
    
    let bstyle = {top:'calc('+dims[1]+'vh - '+bord+"vw)",width:(dims[2]+bord*2)+'vw',height:'calc('+dims[3]+'vh + ' + (bord*2)+'vw)',left:(dims[0]-bord)+'vw'};
    let bstyle2 = {top:'calc('+dims[1]+'vh - '+bord+"vw)",width:(dims[2]+bord*2)+'vw',height:'calc('+dims[3]+'vh + ' + (bord*2)+'vw)',right:(dims[0]-bord)+'vw'};
    let bstyleM = {top:'calc('+dims[1]+'vh - '+bord+"vw)",width:(dims[2]+bord*2)+'vw',height:'calc('+dims[3]+'vh + ' + (bord*2)+'vw)',left:(dims[0]-bord+mOffset)+'vw'};
    let cwid = 0.89 ;
    let chei = 1.8;
    let cwidD = 0.87 ;
    let cheiD = 0.747;
    let sign = 1;
    let corners = [{top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign*-1)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign*-1)+")"}]
    corners[0]['left'] = "calc("+dims[0]+"vw - "+(cwid*1.05)+"vw)";
    corners[1]['left'] = "calc("+(dims[0]+dims[2])+"vw - "+(cwid*2.95)+"vw)"; 
    corners[2]['left'] = "calc("+dims[0]+"vw - "+(cwidD*2.31)+"vw)";
    corners[3]['left'] = "calc("+(dims[0]+dims[2])+"vw - "+(cwidD*2.95)+"vw)";

    let cornersM = [{top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign*-1)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign*-1)+")"}]
    cornersM[0]['left'] = "calc("+dims[0]+"vw - "+(cwid*1.05)+"vw + "+mOffset+"vw)";
    cornersM[1]['left'] = "calc("+(dims[0]+dims[2])+"vw - "+(cwid*2.95)+"vw + "+mOffset+"vw)"; 
    cornersM[2]['left'] = "calc("+dims[0]+"vw - "+(cwidD*2.31)+"vw + "+mOffset+"vw)";
    cornersM[3]['left'] = "calc("+(dims[0]+dims[2])+"vw - "+(cwidD*2.95)+"vw + "+mOffset+"vw)";

    sign = -1;
    let corners2 = [{top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+dims[1]+"vh - "+chei+"vw)",transform:"scaleX("+(sign*-1)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign)+")"},
      {top:"calc("+(dims[1]+dims[3])+"vh - "+cheiD+"vw)",transform:"scaleX("+(sign*-1)+")"}]
    corners2[0]['right'] = "calc("+dims[0]+"vw - "+(cwid*1.05)+"vw)";
    corners2[1]['right'] = "calc("+(dims[0]+dims[2])+"vw - "+(cwid*2.95)+"vw)"; 
    corners2[2]['right'] = "calc("+dims[0]+"vw - "+(cwidD*2.31)+"vw)";
    corners2[3]['right'] = "calc("+(dims[0]+dims[2])+"vw - "+(cwidD*2.95)+"vw)";
    let rightDecor = selectPet ? null : (<div><div className={"SSBorder Right"} style={bstyle2}/>
      <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={corners2[0]}/>
      <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={corners2[1]}/>
      <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={corners2[2]}/>
      <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={corners2[3]}/></div>);
    let decor = (<div>
        <div className={"SSBorder Left"} style={bstyle}/>
        <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={corners[0]}/>
        <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={corners[1]}/>
        <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={corners[2]}/>
        <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={corners[3]}/>

        {rightDecor}

        <div className={"SSBorder Left"} style={bstyleM}/>
        <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={cornersM[0]}/>
        <img src="/Art/UI/SSCornerU.png" className="SSCornerU" style={cornersM[1]}/>
        <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={cornersM[2]}/>
        <img src="/Art/UI/SSCornerD.png" className="SSCornerD" style={cornersM[3]}/>
        {/* <img className={"SSIcon BG " +lr} src="/Art/UI/StatIconBG.png"/>
        <img className={"SSIcon FG " +lr} src="/Art/UI/StatIconFG.png"/> */}
      </div>);

  let Heading = function(txt,list)
    {list.push(<span className='SSHeader PCProfile' key={"H " + list.length}><img className="SSHeaderDots PCProfile" src="/Art/UI/SSHeaderDots.png"/>{' '}{txt}</span>)}
  let Stat = function(txt,list,click,clss){list.push(<span className={'Stat Wide PCProfile' + (click ? " SelectStat" : "") + clss}
    key={"S" + list.length} onClick={click ? e=>click() : null}>{txt}</span>)}
  let content = null;
    let elems = [];
    let elemsM = [];
    let elems2 = [];
    Heading('Gold',elems);
    Stat(God.MoneyAbbr(info.gold) + " Currently",elems);
    Stat(God.MoneyAbbr(info.earnings) + " Lifetime Earnings",elems);
    Heading('Platinum',elems);
    Stat(info.totalplat + " Total",elems);
    Stat(info.monthplat + " This Month",elems);
    Heading('Donations',elems);
    Stat("$"+info.donate + " Total",elems);
    Stat("$"+info.monthdonate + " This Month",elems);
    Heading('Bets',elems);
    Stat(info.bets + " Total",elems);
    Stat("W:"+winp+"%, D:"+drawp+"%, L:"+lossp+"%",elems);
    Heading('Biggest Win',elems);
    Stat(info.bigwin +" gold (" +info.bigwinmon+")",elems);
    Heading('Biggest Loss',elems);
    Stat(info.bigloss +" gold (" +info.biglossmon+")",elems); 

    // console.log("PETS: "+ JSON.stringify(info.pets));
    Heading('Adopted Monsters',elemsM);
    // let pets = [];
    for(let p of info.pets){
      let mon = p.json;
      mon.Name = p.name;
      if (p.stats){
        mon.TStats = p.stats;
        mon.Owner = p.stats.Owner;
      }
      Stat(p.name + " the " + p.type + (p.feat ? " ("+p.feat + ")" : ""),elemsM,e=>{setSelectPet(mon)},(selectPet == mon ? " Highlight" : ""))
      // pets.push(<div className="ProfStat" key={"pet"+pets.length}>{p.name} the {p.type}</div>);
    }
    if (!info.pets || info.pets.length == 0)
      Stat("--none yet--",elemsM)


    content = (<div>
        <span className='SSName'>{info.name}</span>
        <span className='SSInfo'>{rank}</span>
        <div className={"SSStats ProfilePage"}>
          {/* <Scrollbar Offset={0} className="SSScroll" Invert={true}> */}
            {elems}
          {/* </Scrollbar> */}
        </div>
        </div>);
    let contentM = (<div>
      {/* <span className='SSName'>{info.name}</span>
      <span className='SSInfo'>{rank}</span> */}
      <div className={"SSStats ProfilePage"}>
        {/* <Scrollbar Offset={0} className="SSScroll" Invert={true}> */}
          {elemsM}
        {/* </Scrollbar> */}
      </div>
      </div>);
    let content2 = (<div>
      {/* <span className='SSName'>{info.name}</span>
      <span className='SSInfo'>{rank}</span> */}
      <div className={"SSStats ProfilePage"}>
        {/* <Scrollbar Offset={0} className="SSScroll" Invert={true}> */}
          {elems2}
        {/* </Scrollbar> */}
      </div>
      </div>);

    let rightBar = (selectPet != null ? <Statsheet key="SelectPet" who={selectPet} arena={{}} left={false} profile={true} />
     : <div className={"Block Statsheet Right"} style={style2}>{content2} </div>);

    return (<div className="ProfileHolder"><div>{decor}
      <div className={"Block Statsheet Left"} style={style}>  
        {content}
      </div>
      <div className={"Block Statsheet Left"} style={styleM}>  
        {contentM}
      </div>
      {rightBar}
      
    </div></div>);
}

export default Profile; 