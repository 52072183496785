import React from "react";
import DieImg from "./DieImg.js";

var God = {
  Version:"0.5.3",
  StartMoney: 100,
  Cookies:{},
  SaveCharacter:false,
  BS:null,
  MinGold: 50,
  Settings:{Muted:true}, 
  LBDesc:{Plats:"Platinum",MPlats:"Platinum",Donate:"Dollars",MDonate:"Dollars"},
  LBTitle:{MPlats:"Platinum (Monthly)",Plats:"Platinum (Total)",MDonate:"Dollars (Monthly)",Donate:"Dollars (Total)"},
  FloatBreak(amt,key){
    if (amt === undefined)
      amt = 18;
    if (!key)
      key = "BRK: " + Math.random();
    return <div key={key} style={{float:"left",width:"100%",height: amt + 'px'}}/>
  },
  TwoState(a,b,prop){
    if (a[prop] !== undefined)
      return a[prop];
    return b[prop];
  },
  SizeToString(siz){
    switch (siz){
      case -1: return "Tiny";
      case 0: return "Small";
      case 1: return "Medium";
      case 2: return "Large";
      case 3: return "Huge";//Don't remember these, have to check
      case 4: return "Gargantuan";
      default: return siz;
    }
  },
  CRToString(cr){
    switch (cr){
      case -3: return "0";
      case -2: return "1/8";
      case -1: return "1/4";
      case 0: return "1/2";
      default: return "" + cr;
    }
  },
  MoneyAbbr(amt){
    let abbr = "";
    if (amt >= 1000000000000){
      amt = Math.floor(amt / 100000000000);
      amt /= 10;
      abbr = "T"; 
    }else if (amt >= 1000000000){
      amt = Math.floor(amt / 100000000);
      amt /= 10;
      abbr = "B"; 
    }else if (amt >= 1000000){
      amt = Math.floor(amt / 100000);
      amt /= 10;
      abbr = "M"; 
    }else if (amt >= 1000){
      amt = Math.floor(amt / 100);
      amt /= 10;
      abbr = "K"; 
    }
    return amt + abbr;
  },
  Adjectives:[//Poorly Made
    "Nameless",   "Drunken",    "Happy",      "Angry",    "Sleepy",     "Forgotten",  "Cute",       "Undead",     "The",      "Lucky",
    "Hungry",     "Pious",      "Rich",       "Poor",     "Pitiful",    "Proud",      "Envious",    "Enchanted",  "Cursed",   "Unlucky",
    "Ugly",       "Vain",       "Beautiful",  "Hideous",  "Perfumed",   "Smelly",     "Dirty",      "Elite",      "Fake",     "Double",
    "Shy",        "Heroic",     "Hellbound",  "Cannibal", "Unnatural",  "Boss",       "Annoying",   "Magical",    "Greedy",   "Bashful",
    "Boring",     "Normal",     "Abnormal",   "Variant",  "Fast",       "Slow",       "Strong",     "Weak",       "Nimble",   "Clumsy",
    "Tough",      "Fragile",    "Crying",     "Wise",     "Smart",      "Foolish",    "Powerful",   "Confused",   "Invisible","Hidden",
    "Unnerving",  "Calm",       "Loud",       "Quiet",    "Sinful",     "Holy",       "Unholy",     "Hearty",     "Imitation","Gregarious",
    "Artificial", "Decaying",   "Sick",       "Blushing", "Red-Faced",  "Wet",        "Dead",       "Spinning",   "Cat-Eared","Good",
    "Evil",       "Lawful",     "Chaotic",    "Neutral",  "Unaligned",  "Large",      "Small",      "Tiny",       "Huge",     "Slimy",
    "Retired",    "Elderly",    "Young",      "Vengeful", "Tall",       "Short",      "Diseased",   "Coughing",   "Sickly",   "Photosynthetic",
    "Dizzy",      "Thirsty",    "Traveling",  "Wandering","Drowned",    "Ghost Of A", "A",          "Legendary",  "High",     "Philosophical",
    "Just Some",  "Witty",      "Immortal",   "Lvl 1",    "Lvl 99",     "NPC",        "Defeated",   "Undefeated", "Gassy",  //"Social Justice",
    "Deep",       "Low",        "Nude",       "Shadow",   "Big",        "Recruitable","Dark",       "Were-",    "Anachronistic",
    "Un-",        "Anti-",      "Psychic",    "Former",   "Sacrificial","Sacred",     "Gambling",   "Illegal",    "Modified", "Illusionary",
    "Phantom",    "Oozing",     "Celibate",   "Mad",      "Unhatched",  "Peeled",     "Toasted",    "Spoiled",    "Nervous",  "Giant",
    "Blue",       "Rainbow",    "Sparkling",  "Fizzy",    "Woozy",      "Green",      "Lilac",      "Adorable",   "Lil'",     "Little",
    "Gritty",     "Lazy",       "Dramatic",   "Theatrical","Cinematic", "SfX",        "CGI",        "Robot",      "Swollen",  "Fit",
    "Muscular",   "Giddy",      "Playful",    "Needy",    "Fawning",    "Baby",       "Hypnotic",   "Killer",     "Pet",      "Ill",
    "Not A",      "Useless",    "Favorite",   "Partial",  "False",      "Part Of A",  "Ex-",        "Bizarro-",   "Favored",  "Gloomy",
    "Chosen",     "Prone",      "Bad",
  ],
  People:[
    "Goblin",     "Human",      "Orc",        "Elf",      "Halfling",   "Dwarf",      "Barbarian",  "Genasi",     "Tiefling", "Dragonborn",
    "Gnome",      "Kobold",     "Lizardman",  "Bullywug", "Fighter",    "Rogue",      "Wizard",     "Sorcerer",   "Bard",     "Cleric",
    "Druid",      "Monk",       "Paladin",    "Ranger",   "Warlock",    "Vampire",    "Skeleton",   "Zombie",     "Wight",    "Gnoll",
    "Bugbear",    "King",       "Guard",      "Adept",    "Cultist",    "Peasant",    "Merchant",   "Bear",       "Cat",      "Doppleganger",
    "Queen",      "Prince",     "Princess",   "Knight",   "Mercenary",  "Outlaw",     "Bandit",     "Drow",       "Elemental","Merfolk",
    "Minion",     "Fey",        "Ogre",       "Troll",    "Giant",      "Pixie",      "Man",        "Woman",      "Thing",    "Person",
    "Sprite",     "Pixie",      "Redcap",     "Nymph",    "Satyr",      "Hireling",   "Torchbearer","Innkeeper",  "Bartender","Scout",
    "Ragamuffin", "Lord",       "Urchin",     "Shadow",   "Psychic",    "Goblins",    "Elder",      "Hero",       "Villain",  "Scoundrel",
    "Echo",       "Gambler",    "Child",      "Warrior",  "Egg",        "Potato",     "Bread",      "Melon",      "Criminal", "Serpent",
    "Shark",      "Cactus",     "Tree",       "Marshmellow","Onion",    "Snake",      "Peach",      "Avocado",    "Horse",    "Pony",
    "Unicorn",    "Robot",      "Automaton",  "Puppet",   "Magician",   "Shopkeeper", "Farmer",     "Assassin",   "Killer",   "Heiress",
    "Widow",      "Pet",        "Mushroom",   "Wolf",     "Dog",        "Mascot",     "Storyteller","Actor",      "Musician", "Muse",
    "Artist",     "Patron",     "Singer",     "Tomato",   "Pigeon",     "Donkey",     "Pie",        "Broccoli",   "Idol",     "Hand",
    "Ghost",      "Tome",       "Book",       "Treat",    "Sacrifice",  "Fern",       "Plant",      "Blanket",
  ],
  RarePeople:[
    "Dragon",     "Demon",      "Fiend",      "Lich",     "Angel",      "God",        "DMPC",       "Aboleth",//  "Beholder", "Mind-Flayer",
    "Boss",       "Primordial", "Avatar"
  ],
  Abilities:{//Favorite Spell
    "Best Skill":{Options:["Juggling","Athletics","Acrobatics","Sleight of Hand","Stealth","Arcana","History","Investigation","Nature","Religion",
      "Animal Handling","Insight","Medicine","Perception","Survival","Deception","Intimidation","Performance","Persuasion","Fishing"],
      Print(txt){return txt + " +" + (Math.ceil(Math.random()*12))}},
    "Favored Enemy":{Options:["Humans","Goblins","Orcs","Kobolds","Undead","Ghosts","Criminals","Police","Fey","Elves","Dwarves","Halflings",
      "Ogres","Trolls","Giants","Abominations","Celestials","Fiends","Devils","Demons","Beasts","Monstrosities","Slimes","Plants","Constructs",
      "Gnomes","Food","Twitter Users","Gamblers","Yourself","Dragons","God","Purple Guy"]},
    "Ability":{Options:["Arcane Spellcasting","Divine Spellcasting","Turn Undead","Reckless Attack","Sneak Attack","Pick Pockets",
      "Feign Death","Critical Fumble","Poison Immunity","Smite","Yelling Orders","Running Away","Bragging","Disarming Traps","Dying With Dignity",
      "Wildshape"]},
    "Backstory":{Options:["In Crippling Debt","Chosen Champion of God","Self-Styled Hero","Veteran of a Thousand Wars","Runaway Nobility",
      "Degenerate Gambler","Got Lost & Ended Up Here","Looking For Your Lost Sibling","Here To Make Friends","Not Here To Make Friends",
      "Victim of a Curse","Reincarnated Japanese Highschooler","Secretly A Doppleganger","Wandering Soul"]}
  },
  Act2Str(act){
    let effs = "";
    
    let types = ["Hit","Effect"]
    for(let t of types){
      for(let e of act[t]){
        let eff = e;
        // console.log("EFF: " + JSON.stringify(e) + " / "+ JSON.stringify(act[t]) )
        
        let txt = e.Desc ? e.Desc : "";
        if (txt == ""){
        switch(eff.Type){
            case "Damage":{ 
              txt = eff.Amount + " " + eff.DType; 
              if (eff.Effects) txt += " & " + eff.Effects;
              break; }
            case "SDamage":{ txt =eff.Amount + " " + eff.DType; break; }
            case "Condition":{
              txt = eff.Condition; 
              
              // if (eff.Crit)
              break; }
            case "Heal":{ txt = "Heal " + eff.Amount; break; }
            case "Effect":{ txt = eff.Effects; break; }
            case "Actions":{ 
              let temp = "";
              for (let a in eff.Actions){
                if (temp != "")
                  temp += ", ";
                temp += (eff.Actions[a] != 1 && eff.Actions[a] != "1" ? eff.Actions[a] + " " : "") + a;
              }
              if (eff.Random) temp = eff.Random + "x (" + temp + ")"
              txt = temp;
              
              break; }
            default: break;
          }
        }
        if (effs != "" && txt != "")
        effs += ", ";
        effs += txt;
        let maybe = "";
        if (eff.DC) maybe += eff.Save + " Save"; 
        if (eff.Required || eff.MaxSize != undefined || eff.Invalid){
          if (maybe != "") {maybe += ", ";} 
          maybe += "If";
          let sub = "";
          // console.log("REQ: " + JSON.stringify(eff.Required) + " / " + eff.MaxSize)
          if (eff.Required)
            for (let req in eff.Required){
              if (sub != "") {sub += ", ";} 
              sub += " " + req;
            }
          if (eff.MaxSize != undefined){
            if (sub != "") {sub += ", ";} 
            sub += " " + God.SizeToString(eff.MaxSize);
          }
          if (eff.Invalid){
            if (typeof eff.Invalid == 'string'){
              sub += " Not " + eff.Invalid;
            }
            else{
              sub += " Not ";
              let ssub = "";
              if (eff.Invalid[0])
                for (let req of eff.Invalid){
                  if (ssub != "") {ssub += ", ";} 
                  ssub += " " + req;
                }
              sub += ssub;
            }
          }
          maybe += sub;
        }
        if (maybe != "")
          effs += " (" + maybe + ")";
      }
    }
    let pre = "";
    if (act.Acc != undefined)
      pre += (act.Acc >= 0 ? "+" : "") + act.Acc + ", ";
    if (act.Range > 5)
      pre += act.Range + "ft, ";
    let mb = "";
    if (act.Required){
      mb += "If ";
      let sub = "";
      for (let req in act.Required){
        if (sub != "") {sub += "/";} 
        sub += req;
      }
      mb += sub;
    }
    if (mb != "")
      mb = " (" + mb + ")";
    if (act.Desc != undefined){
      if (effs != "") effs += ", ";
      effs += act.Desc;
    }
    if(act.AType) mb += ", " + act.AType;
    let r = (pre + effs + mb).replaceAll("/", "/​");
    return r;
  },
  ReadCookies(){
    if (!God.SaveCharacter) return;
    let c = document.cookie;
    // console.log("Cookies: " + c);
    var output = {};
    if (c == "")
      return output;
    c.split(/\s*;\s*/).forEach(function(pair) {
      pair = pair.split(/\s*=\s*/);
      output[pair[0]] = pair.splice(1).join('=');
    });
    var json = JSON.stringify(output, null, 4);
    // console.log(json);
    God.Cookies = output;
    return output;
  },
  UpdateCookies(key,value){
    if (!God.SaveCharacter) return;
    God.Cookies[key] = value;
    for(let c in God.Cookies){
      document.cookie =  c + "=" + God.Cookies[c] + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    }
  },
  ShowDice(roll,big){
    let dice = [];
    let tempOL = "";
    let dcl = 'LogDie';
    let tcl = 'LogSub ';

    if (roll){
      for(let d in roll.Dice){
        if (roll.Parsed.Adv != 0 && dice.length > 0){
          tempOL = "/"
          dice.push(<span key={"sp"+dice.length} className={tcl+"Outline"} text={tempOL}>{tempOL}</span>)
        }
        // console.log("ROLL : " + d + " / " + roll.Dice[d])
        dice.push(<DieImg key={"die"+dice.length} die={roll.Dice[d]} roll={roll} class={dcl}/>)
        
      }
      
      for(let subr of roll.SubRolls){
        // console.log("SUBROLL: " + JSON.stringify(subr) + " / " + JSON.stringify(roll.SubRolls));
        let subs = God.ShowDice(subr,big);
        dice.push(<span key={"sub"+dice.length}>{subs}</span>);
      }
      if (roll.Parsed.Bonus || roll.SubBonus){
        let bonus = (roll.Parsed.Bonus ? roll.Parsed.Bonus : 0) - roll.SubBonus;

        tempOL = bonus ? "+" + bonus : bonus;
        dice.push(<span key={"sp"+dice.length} className={tcl+"Outline"} text={tempOL}>{tempOL}</span>)
      }
      if (roll.DC){
        
        dice.push(<span key={"sp"+dice.length} className={tcl}>{"  "}&nbsp;</span>)
        tempOL = "(need";
        dice.push(<span key={"sp"+dice.length} className={tcl+"Outline"} text={tempOL}>{tempOL}</span>)
        dice.push(<span key={"sp"+dice.length} className={tcl}>&nbsp;</span>)
        let needednum = roll.DC - roll.Parsed.Bonus;
        if (roll.CritRange){
          needednum = Math.min(Math.max(2,needednum),roll.CritRange);
        }
        dice.push(<DieImg key={"dcd"+dice.length} die={needednum} size={roll.Parsed.Size} class={dcl +" Target"}/>);
        tempOL = ")";
        dice.push(<span key={"sp2"+dice.length} className={tcl+"Outline"} text={tempOL}>{tempOL}</span>);
      }
      
      
    }
    return dice;
  },
  GetSkills(name){
    let r = [];
    let opts = [];
    for(let ab in God.Abilities)
      opts.push(ab);
    for (let n = 0;n < 2;n++){
      let rand = Math.floor(Math.random()*opts.length);
      let chosen = opts[rand];
      opts.splice(rand,1);
      rand = Math.floor(Math.random()*God.Abilities[chosen].Options.length);
      let val = God.Abilities[chosen].Options[rand];
      if (God.Abilities[chosen].Print)
        val = God.Abilities[chosen].Print(val);
      r.push({Key:chosen,Value:val});
    }
    return r;
  },
  GetArtURL(who,url){
    // let r = '/Art/';
    let filen = "Error";
    if (who){
      filen = who.Class.replaceAll(" ","");
      let altArt = who.Tags.AltArt;
      if (altArt != undefined){
        filen = "Alt/"+filen+altArt.Path;
      }
    } 
    else if (url) filen = "UI/"+url;
    // console.log("FILEN: " + filen)
    return '/Art/' + filen + ".png";
  },
  EffectLength(effect){
    switch(effect){
      case "Acid":return 8;
      case "Bludgeoning":return 6;
      case "Cold":return 10;
      case "Fire":return 12;
      case "Force":return 9;
      case "Lightning":return 11;
      case "Necrotic":return 11;
      case "Piercing":return 7;
      case "Poison":return 12;
      case "Psychic":return 11;
      case "Radiant":return 8;
      case "Slashing":return 9;
      case "Thunder":return 9;
      default: return 1;
    }
  }
}

export default God;