import React from "react";
import MIcon from "./MIcon.js"; 
import AIcon from "./AIcon.js"; 

class BWindow extends React.Component {
  
  render() {
    let dist = ".";
    for (let n = 5;n < this.props.dist;n+= 5)
      dist += ".";
    // dist = "|" + dist + "|";
    if (this.props.dist <= 5) 
      dist = null;
    else
      dist = <div className='DistHolder'>|<span className="DistDot">{dist}</span>
        <span className='DistBox'>{this.props.dist} ft.</span><span className="DistDot">{dist}</span>|</div>;
    let size = [20.5,17.44,59.02,37]; 
    let style = {left:size[0]+'vw',top:size[1]+'vh',width:size[2]+'vw',height:size[3]+'vh'};
    let bord = 0.688;
    let bstyle = {left:(size[0]-bord*1.05)+'vw',top:'calc('+size[1]+'vh - '+bord*1.1+'vw)',width:(size[2]+bord*2.1)+'vw',
      height:'calc('+size[3]+'vh + '+(bord*2.1)+'vw)'};
    let cwid = 2.85/2;
    let corners = [{left:"calc("+size[0]+"vw - "+(cwid*1.05)+"vw)",top:"calc("+size[1]+"vh - "+cwid+"vw)"},
      {left:"calc("+(size[0]+size[2])+"vw - "+(cwid*0.95)+"vw)",top:"calc("+size[1]+"vh - "+cwid+"vw)",transform:"scaleX(-1)"},
      {left:"calc("+(size[0])+"vw - "+(cwid*1.05)+"vw)",top:"calc("+(size[1]+size[3])+"vh - "+cwid+"vw)",transform:"scaleY(-1)"},
      {left:"calc("+(size[0]+size[2])+"vw - "+(cwid*0.95)+"vw)",top:"calc("+(size[1]+size[3])+"vh - "+cwid+"vw)",transform:"scaleX(-1) scaleY(-1)"}]

    
    style["backgroundImage"] = "url(/Art/Arenas/"+(this.props.Arena && this.props.Arena.Img ? this.props.Arena.Img : 'Forest')+".png)";
    // style["backgroundColor"] = 'cyan';
    // console.log(JSON.stringify(style))
    // if (this.props.Arena && this.props.Arena.Img){
    //   style["background-color"] = "rgb("+this.props.Arena.Color.R+","+this.props.Arena.Color.G+","+this.props.Arena.Color.B+")";
    // }//
    let cheers = [];
    for(let ch of this.props.Cheers){
      // console.log("CH: " + ch.Img + " / " + ch);
      // let clss = "TossItem";// + (ch.Left ? "Left" : "Right");
      let style = {left:(ch.Left ? 20 : 70) + ch.Offset + "%"}
      cheers.push(<div key={"toss"+ch.ID}>
          {ch.Text && ch.Text != "" ? <div className="TossCheer" style={style}>{ch.Text}</div> : null}
          <img src={"/Art/Items/"+ch.Img} style={style} className="TossItem"/>
        </div>);
    }
    return <div>
      
      <div className="Block BWindow" style={style}>
      <img src={style["background-image"]}/>  
      
      <MIcon who={this.props.left} left={true} zoom={this.props.Zoom} Effect={this.props.LEffect}/> 
      <span className="BWDist">{dist}</span>
      {/* <span className="BWVS">Vs</span> */}
       <MIcon who={this.props.right} left={false} zoom={this.props.Zoom} Effect={this.props.REffect}/>
       {/* <AIcon arena={this.props.Arena}/> */}
       {cheers}
       <div className="BWBorder" style={bstyle}/>
       <img src="Art/UI/BSCorner.png" className="BWCorner" style={corners[0]}/>
       <img src="Art/UI/BSCorner.png" className="BWCorner" style={corners[1]}/>
       <img src="Art/UI/BSCorner.png" className="BWCorner" style={corners[2]}/>
       <img src="Art/UI/BSCorner.png" className="BWCorner" style={corners[3]}/>
       {/* <span className="LastLog">{this.props.Text}</span> */}
    </div></div>;
  }
}



export default BWindow;