import God from "./God.js";

var DB = {
  WS: null,
  Addr(addr){
    return isLocalhost ? 'http://localhost:5000/' + addr : 'https://goblin.bet:5000/' + addr;
  },
  Connect(payload){
    fetch(DB.Addr("Connect"), {credentials: "include",headers: {"Content-Type": "application/json","Access-Control-Allow-Credentials": true}})
    .then(res => res.json())
    .then(r => {payload(r);})
  },
  SendWS(msg){
    if (!msg) return;
    // msg.Acct = acct ? acct.username : undefined;
    // console.log("SWS: " + msg);
    DB.WS.send(msg);
  },
  SendGet(type,payload,backup,expectJSON){
    let add = DB.Addr(type);
    fetch(add, {credentials: "include",headers: {"Content-Type": "application/json","Access-Control-Allow-Credentials": true}})
      .then(r => {
        // console.log("X1: " + r);
        if (!r || r.status != 200){
          if (backup) backup(r);
        }
        else if (expectJSON != false){
          // console.log("X2: " + expectJSON);
          return r.json();
        }
      })  
      .then((r)=>{
        // console.log("X3: " + r);
        if (payload) payload(r);
      })
  },
  SendQuery(type,queryA,queryB,payload,backup,expectJSON){
    let add = DB.Addr(type) +"?" + queryA + "=" + queryB;
    fetch(add, {credentials: "include",headers: {"Content-Type": "application/json","Access-Control-Allow-Credentials": true}})
      .then(r => {
        // console.log("X1: " + r);
        if (!r || r.status != 200){
          if (backup) backup(r);
        }
        else if (expectJSON != false){
          // console.log("X2: " + expectJSON);
          return r.json();
        }
      })  
      .then((r)=>{
        // console.log("X3: " + r);
        if (payload) payload(r);
      })
  },
  SendPost(type,msg,payload,backup){
    let add = DB.Addr(type);
    msg = JSON.stringify(msg);
    fetch(add, {credentials: "include",headers: {"Content-Type": "application/json","Access-Control-Allow-Credentials": true},method: 'post',body: msg})
      .then(r => {
        if (!r || r.status != 200){
          if (backup) backup(r);
        }
        else
          return r.json();
      })  
      .then((r)=>{
        if (payload) payload(r);
      })
  },
  SendLogin(type,username,password,msg,payload,backup){
    let add = DB.Addr(type);
    msg = JSON.stringify(msg);
    fetch(add, {credentials: "include",headers: {//"Content-Type": "application/json","Access-Control-Allow-Credentials": true
      "Authorization":'basic ' + window.btoa(username+":"+password)},method: 'post',body: msg})
      .then(r => {
        if (!r || r.status != 200){
          if (backup) backup(r);
        }
        else
          return r.json();
      })  
      .then((r)=>{
        if (payload) payload(r);
      })
  }
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

God.IsLocal = isLocalhost;

export default DB;