import React from "react";

class Banner extends React.Component {
  
  render() {
    let h = this.props.Height ? this.props.Height+'vmin' : '2.33vmin';
    let dims = {width:this.props.Width,height:h};
    let style = {width:dims.width+'vw',height:dims.height};
    let boopStyle = {width:(dims.width+0.5)+'vw',height:'calc('+dims.height+' - 0.5vw)',zIndex:-1};
    if (this.props.Left){
      dims.left = this.props.Left;
      dims.top = this.props.Top;
      style.position = 'fixed';
      style.left = dims.left+'vw';
      style.top = dims.top+'vh';
      boopStyle.position = 'fixed';
      boopStyle.left = (dims.left-0.25)+'vw';
      boopStyle.top = 'calc('+dims.top+'vh + 0.25vw)';
    }
    let clss = 'Banner';
    if (this.props.Class) 
      clss += ' ' + this.props.Class;
    
    return <div className={clss} style={style}>
      <div className={clss} style={boopStyle}/>
      {this.props.Text}
    </div>
  }
}



export default Banner;