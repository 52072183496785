import React from "react";
import God from "./God.js";
import Banner from "./Banner.js";
import Portrait from "./Portrait.js";
import DB from "./DB.js";
import Username from "./Username.js";

class WelcomeWindow extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Chosen:0,
      Username:"",
      Password:"",
      Sent:false,
      NewPass1:"",
      NewPass2:"",
      PMsg:"",
      CurrentLB:"MPlats",
      Name:""
    };
    this.ChangePassword = this.ChangePassword.bind(this);
    this.ChangeName = this.ChangeName.bind(this);
    this.ResetPassword = this.ResetPassword.bind(this);
  }

  componentDidMount(){
    if (this.props.Settings){
      God.BS.UpdateStats();
    }
    // if (this.state.Name == "" && this.props.Name != "")
    //   this.setState({Name:this.props.Name})
  }

  //this.props.Settings
  componentDidUpdate(prevProps, prevState, snapshot){
    if (!prevProps.Settings && this.props.Settings){
      God.BS.UpdateStats();
    }
    if (this.state.Name == "" && this.props.Name != "")
      this.setState({Name:this.props.Name})
  }

  MakeBet(who){
    this.props.MakeBet(who,100,true);
    this.props.Set("Score",0);
    this.props.Set("LastBet",true);
  }

  ChangePassword(){
    if (this.state.NewPass1 != this.state.NewPass2){
      this.setState({PMsg:"New passwords do not match"});
      return;
    }
    God.BS.ChangePassword(this.state.Password,this.state.NewPass1,this.state.NewPass2,e=>{
      // console.log("CHANGE PASS RESULT: " + JSON.stringify(e));
      this.setState({PMsg: e && e.Message ? e.Message : "???"});
    });
  }

  ChangeName(){
    God.BS.ChangeName(this.state.Name,e=>{
      this.setState({PMsg: e && e.Message ? e.Message : "???"});
    });
  }

  ResetPassword(){
    if (this.state.Username == ""){
      this.setState({PMsg:"Input your email"});
      return;
    }
    God.BS.ResetPassword(this.state.Username,e=>{
      // console.log("RESET PASS RESULT: " + JSON.stringify(e));
      this.setState({PMsg: e && e.Message ? e.Message : "???"});
    });
  }

  HitEnter(e){
    // console.log("KEY CODE: " + e.keyCode);
  }

  Continue(e,fullexit){
    // this.props.Set("Confirmed",true);
    this.props.Set("Returner",false);
    if (this.props.LastBet && this.state.Chosen != 0){
      this.MakeBet(this.state.Chosen == 1 ? "Red" : "Blue");
    }
    if (this.props.Dead){
      this.props.Reroll();
      this.props.Set("Score",God.StartMoney);
      this.props.Set("Dead",false);
      this.props.Set("Confirmed",false);
    }else{
      this.props.Set("Confirmed",true);
    }
    if (this.props.Settings || fullexit){
      this.props.Set("Settings",false);
    }
    if (this.props.TourneyWin){
      this.props.Set("Score",100);
    }
    God.BS.AuditName();
    this.props.Set("Sent",false);
    this.props.Set("TourneyWin",false);
    this.props.Set("Leaderboard",false);
    this.props.Set("Login",false);
    this.props.Set("PMsg","");
    this.setState({"Chosen":0,"Name":""});
    // console.log("CONTINUE!: " + this.props.Register + " / " + this.props.Settings)
  }

  Login(e,reg){
    if (reg){
      this.props.MakeAcct({username:this.state.Username,password:this.state.Password,abils:JSON.stringify(this.props.Abilities)
        ,name:this.props.Name,gold:this.props.score});
    }
    else{
      this.props.Login({username:this.state.Username,password:this.state.Password});
    }
    // if (reg){
    //   this.props.MakeAcct(this.state.Username,this.state.Password,{abils:JSON.stringify(this.props.Abilities)
    //     ,name:this.props.Name,gold:this.props.score});
    // }
    // else{
    //   this.props.Login(this.state.Username,this.state.Password);
    // }
  }

  handleTyping(e,field){
    let st = {};
    st[field] = e.target.value;
    this.setState(st);
  }

  render() {
    let content = null;
    if (this.props.Dead ){
      content = (<div className="WelcomeContent">  
      <div className="WelcomeText Big">A Dark End</div>
      <div className="WelcomeText">My poor, dear <span className="NamePreview">{this.props.Name}</span>--I'm afraid you've lost your bet. And with it, your freedom.<br/><br/>
      I hope you enjoy your new life in my prison.</div>
      <img src="Art/UI/Dungeon.png" className="WelcomeDungeon"/>
      {/* <img src="Art/UI/StartButton.png" className="WelcomeStart" onClick={e=>this.Continue(e)}/> */}
      <div className="WelcomeStart" onClick={e=>this.Continue(e)}>CONTINUE</div>
      </div>);
    }
    else if (this.props.TourneyWin){
      content = (<div className="WelcomeContent">  
      <div className="WelcomeText Big">You Have Ascended</div>
      <div className="WelcomeText"><br/>My good <span className="NamePreview">{this.props.Name}</span>--I have some wondrous news!<br/><br/>
      Of all the players who participated in the most recent tournament, you are within the most wealthy 1%.<br/><br/>
      In recognition of your success, we have converted your vast holdings of gold into a single, but much more valuable, Platinum Coin. This coin can be used to do many things gold can not. You can view your platinum on your Settings page.
      <br/><br/>
      Platinum can currently be spent to adopt monsters as pets, who will then sometimes show up in tournaments under your name. Click the + icon on a monster's stat sheet to show the adoption command.
      <br/><br/>
      You currently have <span className="NamePreview">{this.props.Plat} / {this.props.Stats.totalplat}</span> Platinum coins.
      </div>
      {/* <img src="Art/UI/TourneyWin.png" className="WelcomeDungeon"/> */}
      <div className="WelcomeStart" onClick={e=>{this.Continue(e);DB.SendGet("ConfirmPending")}}>CONTINUE</div>
      </div>);
    }
    else if (this.props.LastBet){
      // console.log("RED: " + JSON.stringify(this.props.Red));
      let icons = this.props.Blue ? (<div>
          <Portrait Who={this.props.Red} Class={'WWIcon Left'} Left={true}/>
          <div className="WWIconText Left" onClick={e=>this.setState({"Chosen":1})}>
            
            {this.props.Red.Name}<br/>
            <img className="Selector" src={"Art/UI/Select"+(this.state.Chosen == 1 ? "Y" :"")+".png"}/></div>
          <Portrait Who={this.props.Blue} Class={'WWIcon Right'} Left={false}/>
          <div className="WWIconText Right" onClick={e=>this.setState({"Chosen":2})}>
            
            {this.props.Blue.Name}<br/>
            <img className="Selector" src={"Art/UI/Select"+(this.state.Chosen == 2 ? "Y" :"")+".png"}/></div>
        </div>) : null;
      content = (<div className="WelcomeContent">  
      <div className="WelcomeText Big">Last Bets</div>
      <div className="WelcomeText">My dear <span className="NamePreview">{this.props.Name}</span>, I can't help but notice that you seem to be short on funds.
      Never fear, my friend--might I extend a proposition that could resolve your situation?<br/><br/>
      I have here 100 gold coins for you--put them on a fighter of your choise. If you bet correctly, and I'm sure you will, you get
      to keep all winning for yourself.<br/><br/>
      If you lose your bet? You shouldn't worry yourself over such little issues.</div>
      {icons}
      {/* <span className="Button BetButton TeamRed" onClick={e=>{this.MakeBet("Red")}}>Bet on Left</span>
      <span className="Button BetButton TeamBlue" onClick={e=>{this.MakeBet("Blue")}}>Bet on Right</span> */}
      {/* <img src="Art/UI/StartButton.png" className="WelcomeStart" onClick={e=>this.Continue(e)}/> */}
      <div className="WelcomeStart" onClick={e=>this.Continue(e)}>CONTINUE</div>
      </div>);
    }
    else if (this.props.Register || (this.props.Settings && !this.props.Account)){
      let reg = this.props.Register ? this.props.Register.NewAcct : false;
      
      content = (<div className="WelcomeContent">  
      <div className="WelcomeText Big">{reg ? "Register" : "Login"}</div>
      <div className="LoginInfo"><form>
        Email<br/>
        <input className="LoginInput" value={this.state.Username} autoComplete="username" onChange={e => this.handleTyping(e,"Username")} maxLength={45}></input><br/>
        {reg ? null :(<div>
        <div className="LoginSubMsg" onClick={e=>this.setState({Chosen: this.state.Chosen ? 0 : 1})}>Forgot your password?</div>
        {this.state.Chosen == 1 ? <div className="SettingsButton" onClick={this.ResetPassword}>Reset Password</div> : null}
        <div className="LoginError">{this.state.PMsg}</div></div>)}<br/>
        Password<br/>
        <input className="LoginInput" type='password' autoComplete="current-password" value={this.state.Password} onChange={e => this.handleTyping(e,"Password")} minLength={6} maxLength={45}></input><br/><br/>
        <div className="LoginError">{this.props.Register ? this.props.Register.Message : ""}</div>
      </form></div>
      
      <div className="WelcomeStart SLeft" onClick={e=>this.Login(e,reg)}>{reg ? "REGISTER" : "LOGIN"}</div>
      <div className="WelcomeStart SRight" onClick={e=>this.Continue(e,true)}>CANCEL</div>
      </div>);
    }
    else if (this.props.Leaderboard){
      let stats = null;
      let title = God.LBTitle[this.state.CurrentLB] ?": " + God.LBTitle[this.state.CurrentLB] : "";
      if (this.props.LBStats && this.props.LBStats[this.state.CurrentLB]){
        let desc = God.LBDesc[this.state.CurrentLB] ? God.LBDesc[this.state.CurrentLB] : "x";
        let plats = [];
        for (let p of this.props.LBStats[this.state.CurrentLB]){
          plats.push(<div className="LBEntry" key={"LBE" + plats.length}>{plats.length + 1}) <Username name={p.name} id={p.id} class="Light"/> - {p.value} {desc}</div>)
        }
        // while(plats.length < 20){
        //   plats.push(<div className="LBEntry" key={"LBE" + plats.length}>{plats.length + 1}) someone - 0 {desc}</div>)
        // }
        stats = <div className="WelcomeText">{plats}</div>;
      }
      else{
        stats = <div className="WelcomeText">Loading...</div>;
      }
      let tabs = [];
      for(let tab in God.LBTitle){
        let tabcl = "LBTab";
        if (tab == this.state.CurrentLB) tabcl += " Active"
        tabs.push(<div key={"tab"+tabs.length} className={tabcl} onClick={e=>{this.setState({CurrentLB:tab})}}>{God.LBTitle[tab]}</div>)
      }
      content = (<div className="WelcomeContent">  
      <div className="LBTabsHolder">{tabs}</div>
          <div className="WelcomeText Big">Leaderboard{title}</div>
          
          {stats}
          <div className="WelcomeStart" onClick={e=>this.Continue(e)}>CONTINUE</div>
          </div>);
    }
    else if (this.props.Settings){
      let stats = null;
      // if (this.props.Stats){ 
      //   let winp = Math.round(this.props.Stats.wins / this.props.Stats.bets * 100);
      //   let drawp = Math.round(this.props.Stats.draws / this.props.Stats.bets * 100);
      //   let lossp = 100 - winp - drawp;
      //   stats = (<div className="WWStatsHolder">
      //     <div className="WWStat">Platinum: {this.props.Plat} / {this.props.Stats.totalplat}</div>
      //     <div className="WWStat">Total Earnings: {this.props.Stats.earnings} gold</div>
      //     <div className="WWStat">Bets: {this.props.Stats.bets} (W:{winp}%, D:{drawp}%, L:{lossp}%)</div>
      //     <div className="WWStat">Biggest W: {this.props.Stats.bigwin} gold ({this.props.Stats.bigwinmon})</div>
      //     <div className="WWStat">Biggest L: {this.props.Stats.bigloss * -1} gold ({this.props.Stats.biglossmon})</div>
      //     </div>);
      // }
      // console.log("STS: " + JSON.stringify(this.props.Stats))
      let name = (this.props.Stats.rank > 1 ? 
        (<div><div className="SettingsHeader">Name</div>
        <input className="LoginInput" autoComplete="nickname" value={this.state.Name} onChange={e => this.handleTyping(e,"Name")} minLength={3} maxLength={20}></input>
        {this.props.Name != this.state.Name ? <div className="SettingsButton" onClick={this.ChangeName}>Change Name</div>:null}</div>) :
        (<div><div className="SettingsHeader">Name</div>
        <span className="LoginInput" value={this.props.Name}>{this.props.Name}</span></div>));

      content = (<div className="WelcomeContent">  
      <div className="WelcomeText Big">Your Account</div>
      <div className="LoginInfo"><form>
        <div className="SettingsHeader">Email</div>
        <span className="LoginInput" value={this.props.Account}>{this.props.Account.username}</span>
        {this.props.Account.auth == 0 ? (this.state.Sent ?<div>Sent</div> :
          <div className="FakeLink Small" onClick={e=>{DB.SendPost("sendEmailVerify",{},e=>{this.setState({Sent:true})})}}>
            Your email isn't verified. Click here to send another verification email.</div> ): null }<br/><br/>
        
        {name}<br/>
        
        <div className="SettingsHeader">Password</div>
        Current Password<br/>
        <input className="LoginInput" type='password' value={this.state.Password} onChange={e => this.handleTyping(e,"Password")} minLength={6} maxLength={45}></input><br/>
        New Password (type twice)<br/>
        <input className="LoginInput" type='password' value={this.state.NewPass1} onChange={e => this.handleTyping(e,"NewPass1")} minLength={6} maxLength={45}></input><br/>
        <input className="LoginInput" type='password' value={this.state.NewPass2} onChange={e => this.handleTyping(e,"NewPass2")} minLength={6} maxLength={45}></input><br/>
        <div className="SettingsButton" onClick={this.ChangePassword}>Change Password</div>
        
        <div className="LoginError">{this.state.PMsg}</div>
      </form></div>
      {stats}
      <div className="WelcomeStart" onClick={e=>this.Continue(e)}>BACK</div>
      </div>);
    }
    else{
      let intro = this.props.Returner ? "Welcome Back!" : "Greetings!";
      let skills = this.props.Abilities;
      let logins = this.props.Account == null ? 
        (<div><div className="WelcomeStart Left" onClick={e=>this.Continue(e)}>CONTINUE</div>
        <div className="WelcomeStart" onClick={e=>God.BS.Set("Login",{NewAcct:false})}>LOGIN</div>
        <div className="WelcomeStart Right" onClick={e=>God.BS.Set("Login",{NewAcct:true})}>REGISTER</div></div>) : 
        <div className="WelcomeStart" onClick={e=>this.Continue(e)}>CONTINUE</div>;
      content = (<div className="WelcomeContent">  
        <div className="WelcomeText Big">{intro}</div>
        <div className="WelcomeText">
          This is the legendary (and highly illegal) goblin arena.<br/>Strange beasts will fight to the death,
          while you bet gold on the winner. Pick wisely.
        </div><br/>
        <div className="WelcomeText Green">+ Your Character +</div><br/>
        <img src="Art/UI/Reroll.png" className="WelcomeRerollDie" title="Reroll Character" onClick={e=>this.props.Reroll()}/>
        <div className="WelcomeGold"><img src="/Art/UI/BetCoin.png"/> {this.props.score}</div>
        <div className="WelcomeBannerHolder"><Banner Text="Name" Width={8} Height={3} Class="Welcome"/></div><br/>
        <div className="RandomStat">{this.props.Name}</div><br/>
        <div className="WelcomeBannerHolder"><Banner Text={skills[0].Key} Width={8} Height={3} Class="Welcome"/></div><br/>
        <div className="RandomStat">{skills[0].Value}</div><br/>
        <div className="WelcomeBannerHolder"><Banner Text={skills[1].Key} Width={8} Height={3} Class="Welcome"/></div><br/>
        <div className="RandomStat">{skills[1].Value}</div><br/>
        {/* <span className="Button ConfirmButton" onClick={e=>this.props.Set("Confirmed",true)}>Let Me Bet</span>
        <span className="Button RandomButton" onClick={e=>this.props.Reroll()}>Reroll My Name</span> */}
        {/* <img src="Art/UI/StartButton.png" className="WelcomeStart" onClick={e=>this.Continue(e)}/> */}
        {logins}
      </div>);
    }
    
    return <div className="Block WelcomeWindow">  <div className="WelcomeHeader"><div className="WelcomeTitle"/></div>
      {content}
    </div>;
  }//keydown={e=>{console.log("E"+e);this.HitEnter(e);}}
}

export default WelcomeWindow;