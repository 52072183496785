import React from "react";
import Username from "./Username";
import God from "./God.js";

class BetsStatus extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      Img:null,
    };
    this.updateCanvas = this.updateCanvas.bind(this);
    this.canv = React.createRef();
  }

  componentDidMount() {
      // let who = this.props.Who;
      let who = this.props.YourBet && this.props.YourBet.ID && this.props.Model ? this.props.Model[this.props.YourBet.ID] : null;//deb
      let src = God.GetArtURL(who,"Unknown");//'/Art/'+(who ? who.Class.replaceAll(" ","") : "UI/"+this.props.Src)+".png";
      let img = new Image();
      let self = this;
      img.onload = function() {
        self.updateCanvas(img);
      };
      img.src = src;
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.YourBet != prevProps.YourBet){
      // let who = this.props.Who;
      let who = this.props.YourBet && this.props.YourBet.ID && this.props.Model ? this.props.Model[this.props.YourBet.ID] : null;//deb
      let src = God.GetArtURL(who,"Unknown");//'/Art/'+(who ? who.Class.replaceAll(" ","") : "UI/"+this.props.Src)+".png";
      let img = new Image();
      let self = this;
      img.onload = function() {
        self.updateCanvas(img);
      };
      img.src = src;
    }
  }
  updateCanvas(img) {
    // let who = this.props.Who;
    let who = this.props.YourBet && this.props.YourBet.ID && this.props.Model ? this.props.Model[this.props.YourBet.ID] : null;//deb
    if (!this.canv || !this.canv.current) return;
    const ctx = this.canv.current.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    let ftemp = who && who.Face ? who.Face : {X:44,Y:70};
    let face = {X:ftemp.X+(ftemp.X/10),Y:ftemp.Y-2};
    let zoom = 5;
    ctx.clearRect(0,0,100,100);
    if(who)
      ctx.drawImage(img,(50-zoom*face.X),(50-zoom*face.Y),100*zoom,100*zoom);
    else
      ctx.drawImage(img,0,0,100,100);
}

  render(){
    // let deb = //this.props.Model ? this.props.Model['Red'] : 
    //   null;
    let who = this.props.YourBet && this.props.YourBet.ID && this.props.Model ? this.props.Model[this.props.YourBet.ID] : null;//deb
    if (who && this.props.YourBet.Who != who.Class)
      who = null;
    // let src = '/Art/'+(who ? who.Class.replaceAll(" ","") : "Unknown")+".png";
    let style = {};
    let left = who && who.ID == 'Red';
    let lr = !who || who.ID == 'Red' ? "Left" : "Right";
    if (who){
  //     left: 47.94vw; + 4
  // top: 11.67vh; + 8.67
      if (who.Tags.Shiny) {
        style.filter = "hue-rotate("+who.Tags.Shiny+"deg)";
      }
      lr += " MonFace"
      // let face = {X:who.Face.X,Y:who.Face.Y};
      // if (left) face.X = 100-face.X;
      // style.left = left ? (50.57 - face.X /5)+'vw' : (49.28 - face.X /5)+'vw';//-3, 47
      // style.top = "calc(15.95vh - "+((face.Y) /5)+'vw)';//-5, 70
      // // calc(15.95vh - 2.45vw);
      // let clips = left ? [face.Y-11.5,face.X-12.2,93.05-face.Y,93.9-face.X] : [face.Y-11.5,87.5-face.X,93.05-face.Y,face.X-5.7];
      // let cltxt = 'inset('+clips[0]+'% '+clips[1]+'% '+clips[2]+'% '+clips[3]+'%)'
      
      // style['clipPath'] = cltxt;
    }
    else 
      lr += " Unknown";

    let returns = "";
    let yourBet = null;
    if (this.props.YourBet && this.props.YourBet.Who){
      yourBet = <span>{this.props.YourBet.Amount}g on {this.props.YourBet.Who}<br/></span>
      let raw = this.props.Returns[this.props.YourBet.ID];
      raw = Math.floor(raw * this.props.YourBet.Amount) + this.props.YourBet.Amount;
      returns = raw ? " ("+raw+")" : "";
    }
    let namesize = "";
    if (this.props.Name.length > 15)
      namesize = " Long";
    let betred = this.props.Totals ? this.props.Totals.Red : 1;
    let betblue = this.props.Totals ? this.props.Totals.Blue : 1;
    let namezone = this.props.Account ? <Username name={this.props.Name} id={this.props.Account.id} /> : this.props.Name;
    return (<div className="BetsStatus">
      <div className="BarLeft"> 
        <span className={"BetsName"+namesize}>{namezone}</span>
        <img className="Dots Left" src="/Art/UI/BetDots.png"/>
      </div>
      <div className="BarMid"> <div className="BetAvatar">
        <img className="BetAvatar BG" src="/Art/UI/BetAvatarBG.png"/>
        <canvas className={"BetAvatar "+lr} ref={this.canv} width="100" height="100" style={style}/>
        {/* <img className={"BetAvatar " + lr} src={src} style={style}/> */}
        <img className="BetAvatar FG" src="/Art/UI/BetAvatar.png"/>
        
        </div><div className="OddsMid">{betred} : {betblue}</div></div>
      <div className="BarRight"> 
        <img className="Dots Right" src="/Art/UI/BetDots.png"/>
        <img className="BetsCoin" src="/Art/UI/BetCoin.png"/>
        <span className="BetsScore">{this.props.Score}</span>
      </div>
      <img className="BetBranch Left" src="/Art/UI/BetBranches.png"/>
      <img className="BetBranch Right" src="/Art/UI/BetBranches.png"/>
    </div>);
  }
}

export default BetsStatus;