import React from "react";
import God from "./God.js";
import ExtraInfo from "./ExtraInfo.js";
import DB from "./DB.js";

class TopBar extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      Team:null,
      Name:"howard",
      Cookies:{},
      Connections:0,
      Account:null,
      Muted:God.Settings.Muted,
    };
    this.Set = this.Set.bind(this);
    this.PlaySound = this.PlaySound.bind(this);
    God.TB = this;
  }

  componentDidMount(){
    this.setState({Cookies:God.ReadCookies()});
  }

  Set = function(p,v){
    let u = {};
    u[p] = v;
    this.setState(u);
    if (p == "Muted"){
      God.Settings.Muted = v;
      DB.SendPost("UpdateSettings",{Setting:"muted",Value:v},(r)=>{
        // console.log("SETTINGS UPDATED: " + JSON.stringify(r));
      });
    }
    // console.log("TB SET: "+ p + " / " + v);
  }

  SetAccount = function(info){
    this.setState({Account:info,Muted:info.muted});
    God.Settings.Muted = info.muted;
    // console.log("TBSA: " + info.muted);
  }

  PlaySound(){
    document.getElementById("AudioPlayer").play();
  }

  render() {
    let cl = "TopBar";
    if (this.state.Team)
      cl += " Team" + this.state.Team;
    // console.log("TITLE: " + cl);
    let dropd = [];

    return <div>
      <div className={cl}>
        <div className="TBContent">
          <ExtraInfo type="Info" Connections={this.state.Connections} Account={this.state.Account}/>
          {/* <ExtraInfo type="Settings"/> */}
          <span className="TopBarIcon"><img src={"./Art/UI/TBChar.png"} onClick={e=>{God.BS.Set("Returner",true)}}/></span>
          <span className="TopBarIcon"><img src={"./Art/UI/TBSettings.png"} onClick={e=>{God.BS.Set("Settings",true)}}/></span>
          <span className="TopBarIcon"><img src={this.state.Muted ? "./Art/UI/TBAudioX.png" : "./Art/UI/TBAudio.png"} onClick={e=>{this.Set("Muted",!this.state.Muted)}}/></span>
          <span className="TopBarIcon"><img src={"./Art/UI/TBLeaders.png"} onClick={e=>{God.BS.OpenLB()}}/></span>
        </div>
        {/* <span className="TopBarIcon"><img src="./Art/UI/TBInfo.png"/></span>
        <span className="TopBarIcon"><img src="./Art/UI/TBSettings.png"/></span>
        <span className="TopBarIcon"><img src="./Art/UI/TBChar.png"/></span> */}
        {/* <span className="TopBarName">{this.state.Name}</span> */}
        <div className="TopBarBorder"/>
      </div>
      <div className="TopBarHeader">
        <div className="TopBarTitle"/>
      </div>
      <audio controls id="AudioPlayer" className="HiddenAudio" src="./Art/Audio/chime.wav"></audio>
      
      {/* <div className="DropDown">
        <div className="DDEntry">Hi</div>
        <div className="DDEntry">What's up? </div>
      </div> */}
    </div>; 
  }
}

export default TopBar;