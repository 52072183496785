import React from "react";
import God from "./God.js";

class ExtraInfo extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      Shown:false
    };
  }

  render() {
    let full = (<div className="ShownInfo">
        <div className="InfoBox"><span className="SmallHeader">Join The Community</span><br/>
          <a href="https://discord.gg/CZdgpC7wkZ" target="_blank">Official Discord</a></div>
        <div className="InfoBox"><span className="SmallHeader">Help Fund Development</span><br/>
          <a href="https://www.patreon.com/goblinbet" target="_blank">Patreon</a>{" or "}
          <a href="https://ko-fi.com/goblinbet" target="_blank">Ko Fi</a></div>
        {/* <div className="InfoBox"><span className="SmallHeader">Contact Us</span><br/>
          <a href="https://forms.gle/poFXC4nExfiAhfMB7" target="_blank">Bugs/Feedback/Etc</a></div> */}
        <div className="InfoBox"><span className="SmallHeader">D&D 5e Rules</span><br/>
          <a href="https://dnd.wizards.com/articles/features/systems-reference-document-srd" target="_blank">Using the SRD/OGL</a></div>
        <div className="InfoBox"><span className="SmallHeader">Programming & Design</span><br/>
          <a href="https://twitter.com/otspiii" target="_blank">Misha Favorov</a></div>
        <div className="InfoBox"><span className="SmallHeader">Pixel Art</span><br/>
          <a href="https://twitter.com/RosalyndMC" target="_blank">Rosalind Chapman</a></div>
        <div className="InfoBox"><span className="SmallHeader">UI Design</span><br/>
          <a href="https://twitter.com/PYarisci" target="_blank">Polat Yarisci</a></div>  
        <div className="InfoBox"><span className="SmallHeader">Version #</span><br/>
          {God.Version}</div>
        <div className="InfoBox"><span className="SmallHeader"># of Gamblers</span><br/>
          {this.props.Connections}</div>
        {this.props.Account ? <div className="InfoBox"><span className="SmallHeader">{this.props.Account.username}</span><br/>
          <span onClick={e=>{God.BS.LogOut(e);this.setState({Shown:false});}}>Log Out</span></div> : null}
      </div>);
    let src = "./Art/UI/TB"+this.props.type+".png";
    return <span className="TopBarIcon">  
      <img src={src} onClick={e=>{this.setState({Shown:!this.state.Shown})}}/>
      {this.state.Shown ? full : null}
    </span>; 
  }
}

export default ExtraInfo;